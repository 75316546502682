export const enTable = {
	youAreOneStep:`You're one step closer to your adventure!`,
	thankYouWeSendYouAnEmail:`Thank you. We sent you an email with instructions about your request.`,
	title: 'Review your trip details',
	availability: 'Availability',
	price: 'Price',
	groupSize: 'Group size',
	partOfAGroup: 'You will be part of a group',
	important: 'Important things to keep in mind',
	fitnessLevel: 'Fitness level',
	skillLevel: 'Skill level',
	departureDates: 'Departure dates',
	beginnerDesc: 'You have some experience but still consider yourself a rookie',
	intermediateDesc: 'You feel comfortable on easy/intermediate terrain but know that there is still a lot to learn!',
	advancedDesc: 'You master the activity and feel comfortable on all types of terrain',
	openToEveryOne:
		'This trip does not involve any considerable aerobic effort, therefore anybody can take part regardless of their fitness level.',
	occasional:
		'This trip is suited for people who occasionally do some kind of aerobic sports e.g.: running, cycling, swimming, soccer, basketball etc, about once or twice a month.',
	fit:
		'This trip is for people who train regularly at least twice a week and can sustain a medium aerobic effort for a relatively long amount of time (3 hours).',
	extremelyFit:
		'This trip demands high levels of aerobic effort and possibly anaerobic effort. It is meant for people who are highly fit, who train at least 3 times a week with high intensity activities(e.g: triathlon-style training or crossfit). It also requires relatively high levels of endurance and stamina.',
	duration: 'Duration',
	durationDescription:
		'The guide will choose {{duration}} {{daysCount}} between the range of available dates you chose. You will be able to modify these dates and talk with the guide after booking.',
	day: 'day',
	tripDays: 'Trip days',
	tripDaysTooltip:
		'These days are chosen by the guide within your available dates. You will always be able to modify what the guide chooses, so you can book now and arrange this later.',
	travelers: 'Travelers',
	travelersPartOfGroup: 'Travelers\nYou will be part of a group',
	pricePerPerson: 'Price per person',
	subtotal: 'Subtotal',
	creditCardFee: 'Credit card fee',
	bankTransferFee: 'Bank transfer fee',
	serviceFee: 'Service fee',
	total: 'Total',
	payNow: 'Pay now',
	payLater: 'Pay later',
	whatsIncluded: "What's Included?",
	guidedTrip: 'guided trip',
	supportAndFaqs: 'Support and FAQs',
	freeCancellation: '48-hour free cancellation period',
	Continue: 'Continue',
	'Continue to Payment': 'Continue to Payment',
	Back: 'Back',
	'Choose how would you like to pay': 'Choose how would you like to pay',
	'Select a payment method': 'Select a payment method',
	'Finish Booking': 'Finish Booking',
	backToCheckout: 'Back to Checkout',
	faq: 'Frequently asked questions (FAQs)',
	send: 'Send',
	enquiry: 'Enquiry',
	enquiryPlaceholder: 'Write your question here',
	lastName: 'Last Name',
	invalidLastName: 'Invalid Last Name',
	firstName: 'First Name',
	stillDoubts: 'Still have doubts? Contact us',
	paymentInformation: 'Payment Information',
	confirmAndPay: 'Confirm details and pay',
	Tips: 'Tips',
	Drinks: 'Drinks',
	'Visa Fee': 'Visa Fee',
	'Other Included': 'Other Included',
	'Hot Shower': 'Hot Shower',
	'Guiding Fee': 'Guiding Fee',
	Lunch: 'Lunch',
	Dinner: 'Dinner',
	Snacks: 'Snacks',
	'Group Porters': 'Group Porters',
	'Lifts Tickets': 'Lifts Tickets',
	'Local Flights': 'Local Flights',
	'Group Eqmnt Rope': 'Rope',
	'Group Eqmnt Skis': 'Skis',
	Breakfast: 'Breakfast',
	'Mules And Horses': 'Mules And Horses',
	'Group Eqmnt Chalk': 'Chalk',
	'Luggage Transfer': 'Luggage Transfer',
	'Personal Porters': 'Personal Porters',
	'Flight To The Start': 'Flight To The Start',
	'Group Eqmnt Helmet': 'Helmet',
	'Group Eqmnt Ice Axe': 'Ice Axe',
	'Camping Eqmnt Tents': 'Tents',
	'Group Eqmnt Harness': 'Harness',
	'Personal Insurance': 'Personal Insurance',
	'Cat Runs As Described': 'Cat Runs As Described',
	'Group Eqmnt Crampons': 'Crampons',
	'Group Eqmnt Ski Boots': 'Ski Boots',
	'Group Eqmnt Ski Poles': 'Ski Poles',
	'Clean Drinkable Water': 'Clean Drinkable Water',
	'Group Eqmnt Crash Pads': 'Crash Pads',
	'Group Eqmnt Quickdraws': 'Quickdraws',
	'Accommodation Included': 'Accommodation Included',
	'Group Eqmnt Hiking Shoes': 'Hiking Shoes',
	'Mountain Guide Expenses': 'Mountain Guide Expenses',
	'Permit And Entrance Fees': 'Permit And Entrance Fees',
	'Transport During The Trip': 'Transport During The Trip',
	'Camping Eqmnt Sleeping Bag': 'Sleeping Bag',
	'Camping Eqmnt Sleeping Pad': 'Sleeping Pad',
	'Group Eqmnt Climbing Shoes': 'Climbing Shoes',
	'Group Eqmnt Trekking Poles': 'Trekking Poles',
	'Helicopter Drops As Described': 'Helicopter Drops As Described',
	'Transportation Start And Back': 'Transportation Start And Back',
	'Camping Eqmnt Cooking Utensils': 'Cooking Utensils',
	'Group Eqmnt Avalanche Equipment': 'Avalanche Equipment',
	'Group Eqmnt Mountaineering Boots': 'Mountaineering Boots',
	'Group Eqmnt Belay And Rappel Devices': 'Belay And Rappel Devices',
  "Meals Breakfast": "Breakfast",
  "Meals Dinner": "Dinner",
};
