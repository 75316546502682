import React from 'react';
import { ThemeProvider } from 'react-jss';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import './App.css';
import { Elements as StripeElements, StripeProvider } from 'react-stripe-elements';
import theme from './theme.js';
import Checkout from './containers/Checkout';
import WindowDimensionProvider from './components/WindowDimensionsProvider';
import BookingError from './components/BookingError';
import BookingSuccess from './components/BookingSuccess';
import { stripePublicKey } from './config';
import I18nContextProvider from './i18n/i18nContext';

const App = ({ error, success, coverUrl }) => {
	return (
		<I18nContextProvider>
			<StripeProvider apiKey={stripePublicKey}>
				<ThemeProvider theme={theme}>
					<WindowDimensionProvider>
						{error && <BookingError />}
						{!error && success && <BookingSuccess coverUrl={coverUrl} />}
						{!error && !success && (
							<StripeElements>
								<Checkout />
							</StripeElements>
						)}
					</WindowDimensionProvider>
				</ThemeProvider>
			</StripeProvider>
		</I18nContextProvider>
	);
};

const mapStateToProps = ({ bookingReducer }) => ({
	error: bookingReducer.error,
	success: bookingReducer.success,
	coverUrl: bookingReducer.booking.trip.cover_url,
});

App.propTypes = {
	error: PropTypes.bool.isRequired,
	success: PropTypes.bool.isRequired,
	coverUrl: PropTypes.string,
};

export default connect(mapStateToProps)(App);
