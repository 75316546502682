/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';

import styles from './modal.module.css';
import Icon from '../../Icon';

const Modal = ({ title, closeButtonHandler, children, isOpen = true }) => (
	<div className={[styles.overlay, isOpen ? styles.show : ''].join(' ')}>
		<div className={styles.container}>
			<div className={styles.header}>
				<div className={styles.title}>{title}</div>
				<div className={styles.exit} onClick={closeButtonHandler}>
					<Icon name="exit" />
				</div>
			</div>
			<div className={styles.content}>{children}</div>
		</div>
	</div>
);

Modal.propTypes = {
	title: PropTypes.string,
	closeButtonHandler: PropTypes.func,
	children: PropTypes.node,
	isOpen: PropTypes.bool,
};

export default Modal;
