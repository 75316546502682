import * as Sentry from '@sentry/browser';
import moment from 'moment';
import * as types from './types';
import API, { getBooking } from '../../utils/api';
import { la2Url } from '../../config';

import {
	generateTravellers,
	extractValues,
	areTravellersValid,
	isValidBooking,
	isPassthroughBooking,
	generateLead,
	getFinalPriceWithFee,
} from './selectors';
import { setPaymentDistribution, lockPaymentDistribution } from '../app/actions';

/*  Action Creators  */
const getBookingStart = () => ({
	type: types.GET_BOOKING_START,
	payload: { isFetching: true },
});
const getBookingEnd = () => ({
	type: types.GET_BOOKING_END,
	payload: { isFetching: false },
});

export const getBookingSuccess = booking => ({
	type: types.GET_BOOKING_SUCCESS,
	payload: { booking },
});

const setSubmitting = isSubmitting => ({
	type: types.SET_SUBMITTING,
	payload: { isSubmitting },
});
const postDataSuccess = () => ({
	type: types.POST_DATA_SUCCESS,
	payload: { success: true },
});

const paymentRequiresAction = paymentData => ({
	type: types.PAYMENT_REQUIRES_ACTION,
	payload: { requiresAction: paymentData },
});

const postTravellersSuccess = () => ({
	type: types.POST_TRAVELLERS_SUCCESS,
	payload: { postTravellersSuccess: true },
});

const getBookingFail = () => ({
	type: types.GET_BOOKING_FAIL,
	payload: { error: true },
});

export const setTravellers = travellers => ({
	type: types.SET_TRAVELLERS,
	payload: { travellers },
});

export const setLead = lead => ({
	type: types.SET_LEAD,
	payload: { lead },
});
export const setPayment = payment => ({
	type: types.SET_PAYMENT,
	payload: { payment },
});

export const setPaymentError = (paymentError = true) => ({
	type: types.SET_PAYMENT_ERROR,
	payload: { paymentError },
});

export const setBookingError = () => ({
	// Generic error to show error view
	type: types.SET_BOOKING_ERROR,
	payload: { error: true },
});

/*  Thunks  */
export const fetchBooking = (bookingId, token) => async dispatch => {
	dispatch(getBookingStart());
	let booking;
	try {
		booking = await getBooking(bookingId);
		// check if booking has state=requested & has not been cancelled by guide
		if (!isValidBooking(booking.data)) {
			const e = new Error(`Invalid Booking ${bookingId}`);
			e.muted = true;
			throw e;
		}
		const firstAvailabilityDate = booking.data.availability.reduce(
			(min, availability) => Math.min(min, +moment(availability.startDate)),
			Infinity
		);
		if (moment(firstAvailabilityDate).diff(moment(), 'days') < 45) {
			dispatch(lockPaymentDistribution(true));
			dispatch(setPaymentDistribution('total-now'));
		}
		dispatch(getBookingSuccess(booking.data));
		dispatch(setTravellers(generateTravellers(booking.data)));
		dispatch(setLead(generateLead(booking.data)));
	} catch (error) {
		if (error.muted && booking && isPassthroughBooking(booking.data)) {
			window.location = `${la2Url}/status/${booking.data._id}?token=${token}`;
			return;
		}
		Sentry.captureException(error);
		dispatch(getBookingFail());
	}
	dispatch(getBookingEnd());
};

export const postPayment = () => async (dispatch, getStore) => {
	const { bookingReducer, app } = getStore();
	const { payment, booking } = bookingReducer;
	const { distribution, method } = app;
	const { address, addressNumber, city, country, phone, zip } = payment;

	// --- First change payment method if necessary
	const shouldChangePaymentMethod = distribution !== 'total-now' || method !== 'cc';

	if (shouldChangePaymentMethod) {
		const paymentMethod = (() => {
			if (distribution === '75-later') return 'deposit';
			if (method === 'wire') return 'wire'; // distribution === 'total-now'
		})();

		const paymentMethodBody = new FormData();
		paymentMethodBody.append('method', paymentMethod);

		if (distribution === '75-later') {
			paymentMethodBody.append(
				'options',
				JSON.stringify({
					deposit_percentage: 25,
					rest_to_the_guide: false,
					payment_method: paymentMethod === 'deposit' && method === 'wire' ? 'cc-wire' : method,
				})
			);
		}

		try {
			if (paymentMethod === 'wire') {
				await API.post(`/billing/leads/${booking.lead._id}`, {
					billing_address: address.value,
					billing_address_number: addressNumber.value,
					billing_city: city.value,
					billing_country: country.value,
					billing_zip: zip.value,
					phone: phone.value,
				});
			}
			// post change payment method
			await API.post(`/dashboard/bookings/change-payment-method/${booking._id}`, paymentMethodBody);
		} catch (err) {
			//  TODO: if we reach this error, we should show a {post-data-eror-view}.  getBookingFail() will show a generic error view
			Sentry.captureException(err);
			dispatch(setSubmitting(false));
			dispatch(setPaymentError());
			return;
		}
	}

	// --- Then, send the payment data if necessary

	const hasToPay = distribution === '75-later' || method === 'cc';
	if (!hasToPay) {
		dispatch(postDataSuccess());
		dispatch(setSubmitting(false));
		if (window.fbq) {
			window.fbq('track', 'Purchase', {
				content_type: 'hotel',
				content_ids: booking.trip._id,
				currency: booking.currency,
				value: getFinalPriceWithFee({ booking }),
			});
		}
		return;
	}

	// format payment data
	const paymentValues = extractValues(payment);
	const fullExpiry = paymentValues.expiry.split('/');
	const currentCentury = new Date()
		.getFullYear()
		.toString()
		.substr(0, 2);

	const body = {
		card_number: paymentValues.number,
		card_holder: paymentValues.name,
		card_expiry_month: parseInt(fullExpiry[0]),
		card_expiry_year: parseInt(`${currentCentury}${fullExpiry[1]}`),
		card_cvv: parseInt(paymentValues.cvv),
		first_name: paymentValues.first_name,
		last_name: paymentValues.last_name,
		phone: paymentValues.phone,
	};

	if (parseInt(booking.customer_fee)) {
		body.billing_address = paymentValues.address;
		body.billing_address_number = paymentValues.address_number;
		body.billing_zip = paymentValues.zip;
		body.billing_city = paymentValues.city;
		body.billing_country = paymentValues.country;
	}

	dispatch(setSubmitting(true));
	try {
		// post payment
		const { data } = await API.post(`/payment/pre-authorize/${booking._id}`, body);
		const { paymentData } = data;
		if (paymentData.status === 'requires_action') {
			dispatch(paymentRequiresAction(paymentData));
		} else {
			dispatch(postDataSuccess());
			dispatch(setSubmitting(false));
		}
		if (window.fbq) {
			window.fbq('track', 'Purchase', {
				content_type: 'hotel',
				content_ids: booking.trip._id,
				currency: booking.currency,
				value: getFinalPriceWithFee({ booking }),
			});
		}
	} catch (err) {
		//  TODO: if we reach this error, we should show a {post-data-eror-view}.  getBookingFail() will show a generic error view
		Sentry.captureException(err);
		dispatch(setSubmitting(false));
		dispatch(setPaymentError());
	}
};

/**
 * Should be called after SCA validation (either if it failed or not)
 */
export const validateSCAValidation = () => async (dispatch, getStore) => {
	const { bookingReducer } = getStore();
	const { booking } = bookingReducer;
	dispatch(setSubmitting(true));
	try {
		await API.post(`/payment/confirm-payment/${booking._id}`, {});
		dispatch(postDataSuccess());
	} catch (err) {
		Sentry.captureException(err);
		dispatch(setPaymentError());
	} finally {
		dispatch(paymentRequiresAction(false));
		dispatch(setSubmitting(false));
	}
};

export const postTravellers = () => async (dispatch, getStore) => {
	const { bookingReducer } = getStore();
	const { lead, travellers, booking } = bookingReducer;

	dispatch(setSubmitting(true));
	try {
		if (!lead || !lead.valid || !areTravellersValid(travellers)) throw new Error('Invalid Lead or Travellers data');
		// update lead
		await API.patch(`/widget/bookings/essential-info/${booking._id}/update`, extractValues(lead));

		if (booking.people_count > 1) {
			// update travellers
			await API.post(`/widget/bookings/extra-info/${booking._id}/travellers`, {
				travellers: travellers.map(extractValues),
			});
		}
		dispatch(postTravellersSuccess());
	} catch (err) {
		Sentry.captureException(err);
		dispatch(setBookingError());
	} finally {
		dispatch(setSubmitting(false));
	}
};
