import { createStore, applyMiddleware } from 'redux';
// import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducer from './modules';

export default (defaultState = {}) => {
	// const middleware = routerMiddleware(history);
	const middlewareChain = [thunk];
	if (process.env.NODE_ENV === 'development') {
		const logger = createLogger();
		middlewareChain.push(logger);
	}
	const store = createStore(reducer, defaultState, composeWithDevTools(applyMiddleware(...middlewareChain)));
	return store;
};
