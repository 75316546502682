import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Text from '@exploreshare/everest/base/Text';
import Image from '@exploreshare/everest/base/Image';
import Grid from '@exploreshare/everest/base/Grid';
import Header from '@exploreshare/everest/base/Header';
import Button from '@exploreshare/everest/base/Button';
import { connect } from 'react-redux';

const styles = theme => ({
	root: {
		maxWidth: theme.breakpoints.values.md,
		margin: '0 auto',
	},
	img: {
		height: 300,
		objectFit: 'cover',
	},
	centerGrid: {
		display: 'flex',
		justifyContent: 'center',
	},
	button: {
		width: 200,
		[theme.breakpoints.down('xs')]: {
			width: '100%',
		},
	},
	buttonActionsBar: {
		[theme.breakpoints.down('xs')]: {
			position: 'absolute',
			bottom: 0,
			left: 0,
			right: 0,
			borderTop: `1px solid ${theme.palette.grey80}`,
			background: 'white',
		},
	},
});

const DEFAULT_IMAGE_URL = 'https://source.unsplash.com/1600x900/?nature,mountain';

const AllSet = ({ classes, coverUrl = DEFAULT_IMAGE_URL, goToStep, goToStatusPage, method, distribution }) => (
	<Grid container spacing={2} className={classes.root}>
		<Grid item xs={12}>
			<Grid container spacing={2} justify="center" style={{ overflow: 'auto' }}>
				<Grid item xs={12} className={classes.centerGrid}>
					<Image className={classes.img} src={coverUrl || DEFAULT_IMAGE_URL} alt="background image" rounded />
				</Grid>
				<Grid item xs={12} className={classes.centerGrid}>
					<Header level={2}>All set! Get ready to start exploring!</Header>
				</Grid>
				<Grid item xs={12} className={classes.centerGrid}>
					<Text align="center" size={14} style={{ maxWidth: 490 }}>
						{distribution === 'total-now' && method === 'wire' ? (
							<>
								Once the payment has been credited to our account, you will receive an email with the trip confirmation
								and the payment receipt. This could take up to 48 hours.
								<br />
							</>
						) : (
							'You will receive an email with the confirmation and the payment receipt.'
						)}
						If you have the time, please fill out some questions that will help your guide prepare the perfect trip for
						you. It will only take you 5 minutes.
					</Text>
				</Grid>
			</Grid>
			<Grid container spacing={2} justify="center" className={classes.buttonActionsBar}>
				<Grid item xs={12} noPaddingX>
					<Grid container spacing={2} justify="center">
						<Grid item xs={12} sm={12} className={classes.centerGrid}>
							<Button className={classes.button} primary onClick={() => goToStep(2)}>
								Give extra details now
							</Button>
						</Grid>
						<Grid item xs={12} sm={12} className={classes.centerGrid}>
							<Button
								className={classes.button}
								primary
								onClick={() => window.open('https://exploreshare.typeform.com/to/uZEx2RPC', '_blank')}>
								Request additional services
							</Button>
						</Grid>
						<Grid item xs={12} sm={12} className={classes.centerGrid}>
							<Button className={classes.button} secondary onClick={goToStatusPage}>
								I will do it later
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	</Grid>
);

AllSet.defaultProps = {
	coverUrl: 'https://source.unsplash.com/1600x900/?nature,mountain',
};

AllSet.propTypes = {
	classes: PropTypes.object,
	coverUrl: PropTypes.string,
	goToStep: PropTypes.func.isRequired,
	goToStatusPage: PropTypes.func,
	method: PropTypes.string,
	distribution: PropTypes.string,
};

/**
 *
 * @param {Once we receive your payment in our account, you will receive an email with the confirmation and the payment receipt. This could take up to 48 hours.
If you have the time, please fill out some questions that will help your guide prepare the perfect trip for you. It’ll only take you 5 minutes.
} state
 */

const mapStateToProps = ({ app }) => ({
	method: app.method,
	distribution: app.distribution,
});

export default connect(mapStateToProps)(injectSheet(styles)(AllSet));
