import moment from 'moment';
import { getIn } from './index';

export const isEmailValid = email => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(email);
export const isNameValid = name => /[a-zA-Z][^#&<>"~;$^%{}?]{1,20}$/.test(name);
export const formatPhone = phone => (phone || '').replace(/[^0-9]/g, '');
export const isPhoneValid = phone => /^[+]?[(]?[0-9]{2,3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{2,12}$/im.test(phone);

function isCreditCardValid(v) {
	let value = v;
	if (/[^0-9-\s]+/.test(value)) return false;
	// ex valid credit card 4009175332806176
	let nCheck = 0;
	let nDigit = 0;
	let bEven = false;
	value = value.replace(/\D/g, '');

	for (let n = value.length - 1; n >= 0; n -= 1) {
		const cDigit = value.charAt(n);
		nDigit = parseInt(cDigit, 10);

		if (bEven) {
			// eslint-disable-next-line no-cond-assign
			if ((nDigit *= 2) > 9) nDigit -= 9;
		}

		nCheck += nDigit;
		bEven = !bEven;
	}

	return nCheck % 10 === 0;
}

const validateName = name => {
	name.errors = [];
	name.valid = true;
	if (!name.required && !name.value) {
		return;
	}
	if (name.required && !name.value) {
		name.valid = false;
		name.errors.push('This field is required.');
		return;
	}
	if (name.value.length > 50) {
		name.valid = false;
		name.errors.push('Field can not be longer than 50 characters');
		return;
	}
	if (/[#&<>"~;$^%{}?]{1,20}$/.test(name.value)) {
		name.valid = false;
		name.errors.push('Field has invalid characters');
	}
};
const validateCardName = name => {
	name.errors = [];
	name.valid = true;
	if (!name.required && !name.value) {
		return;
	}
	if (name.required && !name.value) {
		name.valid = false;
		name.errors.push('This field is required.');
		return;
	}
	if (/[#&<>"~;$^%{}?]{1,20}$/.test(name.value)) {
		name.valid = false;
		name.errors.push('Field has invalid characters');
	}
};

export const validatePhone = phone => {
	phone.errors = [];
	phone.valid = true;

	if (!phone.required && !phone.value) {
		return;
	}
	if (phone.required && !phone.value) {
		phone.valid = false;
		phone.errors.push('Phone is required');
		return;
	}
	if (!isPhoneValid(phone.value)) {
		phone.valid = false;
		phone.errors.push('Please provide a valid phone');
	}
};

const validateEmail = email => {
	email.errors = [];
	email.valid = true;

	if (!email.required && !email.value) {
		return;
	}
	if (email.required && !email.value) {
		email.valid = false;
		email.errors.push('Email is required');
		return;
	}

	if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(email.value)) {
		email.valid = false;
		email.errors.push('Please provide a valid email');
	}
};
const validateTravellerEmail = (traveller, travellers) => {
	const { email } = traveller;
	email.errors = [];
	email.valid = true;

	if (!email.required && !email.value) {
		return;
	}
	if (email.required && !email.value) {
		email.valid = false;
		email.errors.push('Email is required');
		return;
	}

	if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i.test(email.value)) {
		email.valid = false;
		email.errors.push('Please provide a valid email');
		return;
	}
	// get other travellers
	const others = travellers.filter(t => t.id !== traveller.id && t.email.value);
	// check if current traveller email has been used
	const exist = others.find(t => t.email.value === traveller.email.value);
	if (exist) {
		email.valid = false;
		email.errors.push('Travellers can not have the same email');
	}
};

const validateCreditCard = number => {
	number.errors = [];
	number.valid = true;

	if (!number.required && !number.value) {
		return;
	}
	if (number.required && !number.value) {
		number.valid = false;
		number.errors.push('Please insert your card number');
		return;
	}
	if (!/[0-9 ]{13,19}/.test(number.value)) {
		number.valid = false;
		number.errors.push('Number must be from 13 to 19 digits');
		return;
	}
	if (!isCreditCardValid(number.value)) {
		number.valid = false;
		number.errors.push('Invalid credit card number');
	}
};

const validateCvv = number => {
	number.errors = [];
	number.valid = true;

	if (!number.required && !number.value) {
		return;
	}
	if (number.required && !number.value) {
		number.valid = false;
		number.errors.push('Please insert your CVV');
		return;
	}
	if (!/^\d{3,4}$/.test(number.value)) {
		number.valid = false;
		number.errors.push('CVV must be from 3 to 4 digits');
	}
};

const validateExpiry = str => {
	str.errors = [];
	str.valid = true;

	// validate
	if (str.required && !str.value) {
		str.valid = false;
		str.errors.push('Please insert the exp. date');
		return;
	}

	if (!/^\d{2}\/\d{2}$/g.test(str.value)) {
		str.valid = false;
		str.errors.push('Invalid format (MM/DD)');
		return;
	}

	const expiryDate = moment(str.value, 'MM/YY');

	if (!expiryDate.isValid()) {
		str.valid = false;
		str.errors.push('Invalid date');
		return;
	}

	if (expiryDate.isBefore(moment().startOf('month'))) {
		str.valid = false;
		str.errors.push('The card has expired');
	}
};

const validateAddress = str => {
	str.errors = [];
	str.valid = true;

	if (!str.required && !str.value) {
		return;
	}

	if (str.required && !str.value) {
		str.valid = false;
		str.errors.push('This field is required');
	}
};

const validateAddressNumber = str => {
	str.errors = [];
	str.valid = true;

	if (!str.required && !str.value) {
		return;
	}

	if (str.required && !str.value) {
		str.valid = false;
		str.errors.push('This field is required');
	}
};

const validateZip = str => {
	str.errors = [];
	str.valid = true;

	if (!str.required && !str.value) {
		return;
	}

	if (str.required && !str.value) {
		str.valid = false;
		str.errors.push('This field is required');
	}
};

const validateCity = str => {
	str.errors = [];
	str.valid = true;

	if (!str.required && !str.value) {
		return;
	}

	if (str.required && !str.value) {
		str.valid = false;
		str.errors.push('This field is required');
	}
};

const validateCountry = str => {
	str.errors = [];
	str.valid = true;

	if (!str.required && !str.value) {
		return;
	}

	if (str.required && !str.value) {
		str.valid = false;
		str.errors.push('This field is required');
	}
};

const validateNotEmpty = str => {
	str.errors = [];
	str.valid = true;

	if (!str.required && !str.value) {
		return;
	}
	if (str.required && !str.value) {
		str.valid = false;
		str.errors.push('This field is required');
	}
};

export const validateAcceptedTerms = acceptedTerms => {
	if (acceptedTerms.required && !acceptedTerms.value) {
		acceptedTerms.errors = ['Please accept terms and conditions'];
		acceptedTerms.valid = false;
		return;
	}
	acceptedTerms.errors = [];
	acceptedTerms.valid = true;
};

export const validateTravellerInfo = (traveller, travellers = []) => {
	const { firstName, lastName, phone, email } = traveller;
	validateName(firstName);
	validateName(lastName);
	validateTravellerEmail(traveller, travellers);
	validatePhone(phone);
	traveller.valid = firstName.valid && lastName.valid && (phone.required ? phone.valid : true) && email.valid;
};

export const validateContactInfo = contactInfo => {
	const { firstName, lastName, email, phone } = contactInfo;

	validateName(firstName);
	validateName(lastName);
	validateEmail(email);
	validatePhone(phone);
	contactInfo.valid = [firstName, lastName, email, phone].reduce((memo, field) => memo && field.valid, true);
};

export const validateContactUs = group => {
	const { firstName, lastName, enquiry } = group;
	validateName(firstName);
	validateName(lastName);
	validateNotEmpty(enquiry);
	group.valid = [firstName, lastName, enquiry].reduce((memo, field) => memo && field.valid, true);
};

export const validateBillingInfo = billingInfo => {
	const { address, addressNumber, zip, city, country } = billingInfo;
	validateAddress(address);
	validateAddressNumber(addressNumber);
	validateZip(zip);
	validateCity(city);
	validateCountry(country);

	billingInfo.valid = address.valid && addressNumber.valid && zip.valid && city.valid && country.valid;
};

export const validatePayment = payment => {
	const {
		firstName,
		lastName,
		phone,
		name,
		number,
		cvv,
		expiry,
		address,
		addressNumber,
		zip,
		city,
		country,
		acceptedTerms,
	} = payment;
	// eslint-disable-next-line global-require
	const { store } = require('../index');
	const state = store.getState();
	const customerFee = parseInt(getIn(state, ['bookingReducer', 'booking', 'customer_fee'], 0));

	validateName(firstName);
	validateName(lastName);
	validatePhone(phone);
	validateCardName(name);
	validateCreditCard(number);
	validateCvv(cvv);
	validateExpiry(expiry);
	validateAcceptedTerms(acceptedTerms);

	if (customerFee) {
		validateBillingInfo(payment);
	}

	const paymentInfoValid = name.valid && number.valid && cvv.valid && expiry.valid;
	const personalInfoValid = firstName.valid && lastName.valid && phone.valid;
	const billingInfoValid =
		!customerFee || (address.valid && addressNumber.valid && zip.valid && city.valid && country.valid);

	payment.valid = paymentInfoValid && personalInfoValid && billingInfoValid && acceptedTerms.valid;
};
