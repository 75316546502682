export default theme => ({
	actions: {
		position: 'sticky',
		bottom: 0,
		left: 0,
		right: 0,
		backgroundColor: '#FFF',
		borderTop: 'solid 1px #d3d3db',
		height: 40,
		padding: '8px 16px',
		zIndex: 13,
	},

	'@supports(padding: max(0px))': {
		actions: {
			paddingBottom: 'max(8px, env(safe-area-inset-bottom))',
		},
	},

	decline: {
		flexGrow: 1,
		marginRight: 8,
		maxWidth: 120,
	},

	accept: {
		flexGrow: 2,
		maxWidth: 240,
	},

	buttons: {
		display: 'flex',
		justifyContent: 'space-around',
	},
	freeCancellation: {
		flexGrow: 1,
		alignSelf: 'center',
		textAlign: 'right',
		display: 'inline',
	},
	faq: {
		display: 'none',
		flexGrow: 1,
		alignSelf: 'center',
		textAlign: 'right',
		'& p': {
			color: theme.palette.blue,
			display: 'inline',
			cursor: 'pointer',
		},
	},
	onlyPrimary: {},
	[theme.breakpoints.down(800)]: {
		onlyPrimary: {
			maxWidth: 'unset',
		},
	},
	[theme.breakpoints.up(800)]: {
		buttons: {
			maxWidth: 'none',
			display: 'flex',
			justifyContent: 'left',
		},
		actions: {
			border: 'solid 1px #d3d3db',
			borderRadius: 4,
			bottom: 10,
		},

		faq: {
			display: 'block',
		},
	},
});
