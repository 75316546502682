import id from './id';

const createType = type => `${id}/${type}`;

export const GET_BOOKING_START = createType('GET_BOOKING_START');
export const GET_BOOKING_SUCCESS = createType('GET_BOOKING_SUCCESS');
export const GET_BOOKING_FAIL = createType('GET_BOOKING_FAIL');
export const GET_BOOKING_END = createType('GET_BOOKING_END');
export const SET_BOOKING_ERROR = createType('SET_BOOKING_ERROR');
export const SET_PAYMENT_ERROR = createType('SET_PAYMENT_ERROR');
export const SET_TRAVELLERS = createType('SET_TRAVELLERS');
export const SET_LEAD = createType('SET_LEAD');
export const SET_PAYMENT = createType('SET_PAYMENT');
export const SET_SUBMITTING = createType('SET_SUBMITTING');
export const POST_DATA_SUCCESS = createType('POST_DATA_SUCCESS');
export const PAYMENT_REQUIRES_ACTION = createType('PAYMENT_REQUIRES_ACTION');
export const POST_TRAVELLERS_SUCCESS = createType('POST_TRAVELLERS_SUCCESS');
