import React from 'react';
import PropTypes from 'prop-types';

import Text from '@exploreshare/everest/base/Text';
import Icon from '../../Icon';
// import Heading from '../../Heading';

import styles from './ImportantItem.module.css';

const ImportantItem = ({ icon, title, subtitle, description }) => (
	<div className={styles.container}>
		<div className={styles.title}>
			<div className={styles.icon}>
				<Icon size="24px" name={icon} />
			</div>
			<div>
				<Text size="14px" bold color="grey">
					{title}
				</Text>
				<div>
					<Text bold className={styles.subtitle}>
						{subtitle}
					</Text>
				</div>
			</div>
		</div>
		<div>
			<Text color="grey">{description}</Text>
		</div>
	</div>
);

ImportantItem.propTypes = {
	icon: PropTypes.string,
	title: PropTypes.string,
	subtitle: PropTypes.string,
	description: PropTypes.string,
};

export default ImportantItem;
