import * as types from './types';

export const setPaymentDistribution = distribution => ({
	type: types.SET_PAYMENT_DISTRIBUTION,
	payload: distribution,
});

export const lockPaymentDistribution = lock => ({
	type: types.LOCK_PAYMENT_DISTRIBUTION,
	payload: lock,
});

export const setPaymentMethod = method => ({
	type: types.SET_PAYMENT_METHOD,
	payload: method,
});
