import React from 'react';
import injectStyles from 'react-jss';
import PropTypes from 'prop-types';
import Modal from '@exploreshare/everest/base/Modal';
import Grid from '@exploreshare/everest/base/Grid';
import Text from '@exploreshare/everest/base/Text';
import Input from '@exploreshare/everest/base/Input';
import Button from '@exploreshare/everest/base/Button';
import Image from '@exploreshare/everest/base/Image';
import infoRed from '../../assets/img/infoRed.svg';

import PhoneInput from '../Commons/Form/PhoneInput';

const styles = {
	root: {
		padding: 8,
	},
	alignMiddle: {
		display: 'flex',
		justifyContent: 'center',
	},
	errorImage: {
		width: 77,
		height: 77,
	},
	button: {
		width: 273,
	},
	image: {
		height: '40px',
		width: 'auto',
	},
};

const getInputData = (data, field, onChange) => ({
	name: field,
	value: data[field].value,
	handleChange: onChange(field),
	isValid: data[field].valid,
	ref: data[field].ref,
	errorLabels: data[field].errors,
});

const WidgetModal = ({
	classes,
	isOpen,
	contactInfo,
	handleChange,
	tripPageUrl,
	onSubmit,
	error,
	back,
	isSubmiting,
}) => {
	function backToTripPage() {
		if (!tripPageUrl) return window.history.back();
		return (window.location = tripPageUrl);
	}
	const { phone } = contactInfo;
	return (
		<Modal
			isOpen={isOpen}
			title={
				<Image
					src="https://d3rrfy0o57uet0.cloudfront.net/themes/twentysixteen-child/images/E&S-black.svg"
					alt="logo"
					className={classes.image}
				/>
			}>
			{error ? (
				<Grid container spacing={2} className={classes.root} justify="center">
					<Grid item className={classes.alignMiddle} xs={12}>
						<Image src={infoRed} alt="Success message Icon" className={classes.errorImage} />
					</Grid>
					<Grid item className={classes.alignMiddle} xs={12}>
						<Text size={20} bold>
							Something went wrong
						</Text>
					</Grid>
					<Grid item className={classes.alignMiddle} xs={12}>
						<Text size={14} style={{ textAlign: 'center' }}>
							Please try again or go back to trip page
						</Text>
					</Grid>
					<Grid item className={classes.alignMiddle} xs={12}>
						<Button className={classes.button} onClick={back} primary>
							Try again!
						</Button>
					</Grid>
					<Grid item className={classes.alignMiddle} xs={12}>
						<Button className={classes.button} secondary onClick={backToTripPage}>
							Back to trip page
						</Button>
					</Grid>
				</Grid>
			) : (
				<Grid container spacing={2} className={classes.root}>
					<Grid item xs={12}>
						<Text size={20} bold>
							Contact information
						</Text>
					</Grid>
					<Grid item xs={12}>
						<Text size={14}>Please provide this information to continue</Text>
					</Grid>
					<Grid item xs={6}>
						<Input placeholder="Indiana" label="First Name" {...getInputData(contactInfo, 'firstName', handleChange)} />
					</Grid>
					<Grid item xs={6}>
						<Input
							placeholder="Jones"
							label="Last Name"
							{...getInputData(contactInfo, 'lastName', handleChange)}
							optional
						/>
					</Grid>
					<Grid item xs={12}>
						<Input
							placeholder="indianajones@explorer.com"
							label="Email"
							type="email"
							{...getInputData(contactInfo, 'email', handleChange)}
						/>
					</Grid>
					<Grid item xs={12}>
						<PhoneInput
							name="phone"
							value={phone.value}
							onChange={value => handleChange('phone')({ target: { value } })}
							errorLabels={phone.errors}
							valid={phone.valid}
							optional
						/>
					</Grid>
					<Grid item xs={12}>
						<Button primary fullWidth onClick={onSubmit} disabled={isSubmiting}>
							{isSubmiting ? 'Loading..' : 'Continue'}
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Button secondary fullWidth onClick={backToTripPage}>
							Back to trip page
						</Button>
					</Grid>
				</Grid>
			)}
		</Modal>
	);
};

WidgetModal.propTypes = {
	classes: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
	contactInfo: PropTypes.shape({
		firstName: PropTypes.object,
		lastName: PropTypes.object,
		phone: PropTypes.object,
		email: PropTypes.object,
	}),
	handleChange: PropTypes.func.isRequired,
	tripPageUrl: PropTypes.string,
	onSubmit: PropTypes.func.isRequired,
	error: PropTypes.bool,
	back: PropTypes.func,
	isSubmiting: PropTypes.bool,
};

export default injectStyles(styles)(WidgetModal);
