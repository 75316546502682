import React from 'react';
import PropTypes from 'prop-types';

import Text from '@exploreshare/everest/base/Text';
import styles from './SummaryItem.module.css';

const SummaryItem = ({ title, content, bold = false, ...rest }) => (
	<div className={styles.container} {...rest}>
		<div className={styles.title}>
			<Text bold={bold} color={bold ? 'black' : 'grey'} size="14px">
				{title}
			</Text>
		</div>
		<div>
			<Text bold={bold} size="14px">
				{content}
			</Text>
		</div>
	</div>
);

SummaryItem.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	bold: PropTypes.bool,
};

export default SummaryItem;
