import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// TODO: be able to add this as { Component } from @exploreshare/everest
import Grid from '@exploreshare/everest/base/Grid';
import Text from '@exploreshare/everest/base/Text';

import useI18n from '../../../i18n/usei18n';

import styles from './Loading.module.css';

const Logo = () => {

	return <svg className={styles.loading} width="80" height="80" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M118.65 60.0899C118.65 92.4851 92.2904 118.834 59.896 118.834C27.5008 118.834 1.1515 92.4851 1.1515 60.0899C1.1515 27.6955 27.5008 1.3359 59.896 1.3359C92.2904 1.3359 118.65 27.6955 118.65 60.0899Z" fill="white" />
		<path d="M59.901 1.33997C27.5059 1.33997 1.15138 27.6922 1.15138 60.0881C1.15138 92.4833 27.5059 118.838 59.901 118.838C92.2969 118.838 118.652 92.4833 118.652 60.0881C118.652 27.6922 92.2969 1.33997 59.901 1.33997ZM59.901 119.989C26.8712 119.989 -6.10352e-05 93.1157 -6.10352e-05 60.0859C-6.10352e-05 27.0561 26.8712 0.184842 59.901 0.184842C92.9323 0.184842 119.803 27.0561 119.803 60.0859C119.803 93.1157 92.9323 119.989 59.901 119.989Z" fill="#BBBBBB" />
		<path d="M59.902 5.41995C29.7575 5.41995 5.2303 29.9471 5.2303 60.0946C5.2303 90.2406 29.7575 114.765 59.902 114.765C90.048 114.765 114.574 90.2406 114.574 60.0946C114.574 29.9471 90.048 5.41995 59.902 5.41995Z" fill="#006993" />
		<path d="M113.33 71.69C108.006 96.2945 86.0685 114.774 59.8996 114.774C38.3835 114.774 19.7145 102.27 10.8114 84.1424L69.5153 47.7975L113.33 71.69Z" fill="#6A9D25" />
		<path d="M113.94 68.3899V68.4098L113.328 71.6882L69.5136 47.7957L10.8088 84.1509C9.97691 82.4672 9.23554 80.7327 8.58398 78.9481L48.959 29.5484C48.959 29.5484 52.5488 24.2742 56.8903 23.9031C61.4623 23.5225 65.3127 27.5732 65.3127 27.5732L113.94 68.3899Z" fill="white" />
		<path className={styles.shadow} d="M94.713 91.82C92.5257 90.8762 86.1677 90.4197 80.5135 90.8033C78.3233 90.9505 76.5674 91.2023 75.3563 91.51L75.3482 91.5064L67.3573 93.676L81.9109 94.3106L81.9013 94.3062C84.0017 94.3865 86.2715 94.3636 88.4397 94.2171C94.0938 93.835 96.9018 92.7638 94.713 91.82" fill="#1A1919" />
		<path className={styles.pointer} d="M88.864 78.99C87.6706 81.5565 85.7557 83.5722 83.48 84.8857L83.5102 84.8952L67.3575 93.6783L63.5675 75.6814L63.5874 75.6917C63.1066 73.0745 63.407 70.2872 64.6203 67.6898C67.7389 60.9931 75.6996 58.0954 82.397 61.2132C89.0849 64.3414 91.9922 72.2919 88.864 78.99" fill="white" />
		<path className={styles.point} d="M71.972 71.17C70.7425 73.8056 71.8837 76.9346 74.5186 78.1648C77.1535 79.3921 80.2839 78.2546 81.5127 75.6167C82.7407 72.9789 81.601 69.8521 78.9639 68.6241C76.3297 67.3939 73.1986 68.5358 71.972 71.17Z" fill="#DE4321" />
		<path className={styles.pointRotate} d="M71.972 71.17C70.7425 73.8056 71.8837 76.9346 74.5186 78.1648C77.1535 79.3921 80.2839 78.2546 81.5127 75.6167C82.7407 72.9789 81.601 69.8521 78.9639 68.6241C76.3297 67.3939 73.1986 68.5358 71.972 71.17Z" fill="#DE4321" />
	</svg>
}

const LoadingDots = () => {

	return <svg style={{ margin: 'auto', display: 'block', shapeRendering: 'auto' }} width="50px" height="50px" viewBox="0 0 100 100">
		<circle cx="84" cy="50" r="10" fill="#2077d5">
			<animate attributeName="r" repeatCount="indefinite" dur="0.25s" calcMode="spline" keyTimes="0;1" values="10;0" keySplines="0 0.5 0.5 1" begin="0s"></animate>
			<animate attributeName="fill" repeatCount="indefinite" dur="1s" calcMode="discrete" keyTimes="0;0.25;0.5;0.75;1" values="#2077d5;#9da2a3;#242f33;#d0021b;#2077d5" begin="0s"></animate>
		</circle><circle cx="16" cy="50" r="10" fill="#2077d5">
			<animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
			<animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="0s"></animate>
		</circle><circle cx="50" cy="50" r="10" fill="#d0021b">
			<animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.25s"></animate>
			<animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.25s"></animate>
		</circle><circle cx="84" cy="50" r="10" fill="#242f33">
			<animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.5s"></animate>
			<animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.5s"></animate>
		</circle><circle cx="16" cy="50" r="10" fill="#9da2a3">
			<animate attributeName="r" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="0;0;10;10;10" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.75s"></animate>
			<animate attributeName="cx" repeatCount="indefinite" dur="1s" calcMode="spline" keyTimes="0;0.25;0.5;0.75;1" values="16;16;16;50;84" keySplines="0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1;0 0.5 0.5 1" begin="-0.75s"></animate>
		</circle>
	</svg>
}


const Component = () => {
	const { t } = useI18n();
	return (
		<Grid container justify="center" spacing={10} >
			<Grid xs md={8} item>
				<Text className={styles.fadeIn} style={{ textAlign: 'center', marginTop: 50 }}>
					<Logo />
					<LoadingDots />
				</Text>
				<Text className={styles.fadeIn} style={{ textAlign: 'center', fontSize: 16, color: '#65747a' }}>
					{t('youAreOneStep')}
				</Text>
				<Text className={styles.fadeIn} style={{ lineHeight: '28px', marginTop: '20px', textAlign: 'center', fontSize: 22, color: '#333' }}>
					{t('thankYouWeSendYouAnEmail')}
				</Text>
			</Grid>
		</Grid>
	)
}

/**
 * Loading
 * Main pourpuse of this component is to allow readability on components
 * while removing responsability from components and also incresing reusability
 */
const Loading = ({ isLoading, children }) => {
	const [ready, setReady] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setReady(true);
		}, 5000);
	});

	return !isLoading && ready ? children : <Component />;
};

Loading.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	children: PropTypes.node,
};

export { Loading };
