import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Tooltip from '@exploreshare/everest/base/Tooltip';
import moment from 'moment';
import Header from './Header';
import styles from './TripSummary.module.css';
import SummaryItem from '../Commons/Items/SummaryItem';
import SummaryInfo from '../Commons/Items/SummaryInfo';

import { selectors } from '../../modules/booking';
import useI18n from '../../i18n/usei18n';
import { CREDIT_CARD_FEE } from '../../utils';

/*
TODO: define if we are going to show trip days : 2 ( Aug 3 to Aug 5)
const parseDate = date => moment(date).format('MMM D');
*/

const upperFirst = word => word.charAt(0).toUpperCase() + word.slice(1);

const TripSummary = ({
	duration,
	title,
	peopleCount,
	pricePerPerson,
	currency,
	finalPrice,
	finalPriceWithFee,
	whatsIncluded,
	daysCount,
	coverURL,
	creditCardFee,
	distribution,
	availability,
	openGroup,
}) => {
	const { t } = useI18n();

  const getCcFee = percentage => {
    return finalPrice * percentage * (CREDIT_CARD_FEE / 100)
  }
  
  const showCcFee = creditCardFee || distribution === '75-later'
	const decimals = typeof creditCardFee === 'number' || finalPrice !== finalPriceWithFee ? 2 : 0;
	return (
		<div className={styles.container}>
			<Header
				title={title}
				coverURL={coverURL}
				description={`${duration}-${daysCount.replace(/day/, t('day')).replace('s', '')} ${t('guidedTrip')}`}
			/>
			<hr />
			<SummaryItem
				title={t('availability')}
				content={availability
					.map(({ startDate, endDate }) => `${moment(startDate).format('ll')} > ${moment(endDate).format('ll')}`)
					.join('\n')}
			/>
			<SummaryItem title={t('tripDays')} id="tripDays" content={duration} />
			<Tooltip position="bottom" elementID="tripDays" label={t('tripDaysTooltip')} />

			<SummaryItem title={openGroup ? t('travelersPartOfGroup') : t('travelers')} content={peopleCount} />

			{pricePerPerson && (
				<SummaryItem title={t('pricePerPerson')} content={`${currency} ${pricePerPerson.toFixed(decimals)}`} />
			)}
			{(typeof creditCardFee === 'number' || finalPrice !== finalPriceWithFee) && (
				<>
					<hr />
					<SummaryItem title={t('subTotal')} content={`${currency} ${finalPrice.toFixed(decimals)}`} />
				</>
			)}
			{creditCardFee || distribution === '75-later' ? (
				<SummaryItem title={`+ ${t('creditCardFee')}`} content={`${currency} ${(distribution === '75-later' ? (creditCardFee || 0) + getCcFee(0.25) : creditCardFee).toFixed(decimals)}`} />
			) : (
				typeof creditCardFee === 'number' && (
					<SummaryItem title={`+ ${t('bankTransferFee')}`} content={`${currency} ${(0).toFixed(decimals)}`} />
				)
			)}
			{finalPrice !== finalPriceWithFee && (
				<SummaryItem
					title={`+ ${t('serviceFee')}`}
					content={`${currency} ${(finalPriceWithFee - finalPrice).toFixed(decimals)}`}
				/>
			)}
			<hr />
			<SummaryItem
				title={t('total')}
				content={`${currency} ${(finalPriceWithFee + (showCcFee ? getCcFee(creditCardFee ? 1 : 0.25) : 0)).toFixed(decimals)}`}
				bold
			/>
			{distribution === '75-later' && (
				<>
					<hr />
					<SummaryItem
						title={t('payNow')}
						content={`${currency} ${((finalPrice * 0.25 + finalPriceWithFee - finalPrice) + getCcFee(0.25)).toFixed(decimals)}`}
						bold
					/>
					<SummaryItem
						title={t('payLater')}
						content={`${currency} ${(finalPrice * 0.75 + (creditCardFee || 0)).toFixed(decimals)}`}
					/>
				</>
			)}
			<hr />
			{whatsIncluded && whatsIncluded.length >= 1 && (
				<SummaryInfo
					title={t('whatsIncluded')}
					description={whatsIncluded
						.map(v =>
							t(
								v
									.split(' ')
									.map(w => upperFirst(w))
									.join(' ')
							)
						)
						.join(', ')}
				/>
			)}
		</div>
	);
};

const mapStateToProps = ({ bookingReducer, app }) => ({
	creditCardFee: selectors.getCreditCardFee(bookingReducer, app),
	distribution: app.distribution,
	method: app.method,
});

const mapDispatchToProps = () => ({});

TripSummary.propTypes = {
	duration: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	coverURL: PropTypes.string,
	peopleCount: PropTypes.number.isRequired,
	pricePerPerson: PropTypes.number,
	currency: PropTypes.string.isRequired,
	finalPrice: PropTypes.number.isRequired,
	finalPriceWithFee: PropTypes.number.isRequired,
	whatsIncluded: PropTypes.arrayOf(PropTypes.string),
	daysCount: PropTypes.string.isRequired,
	creditCardFee: PropTypes.number,
	distribution: PropTypes.string,
	openGroup: PropTypes.string,
	availability: PropTypes.array,
};

const connectedPayment = connect(mapStateToProps, mapDispatchToProps)(TripSummary);
export default connectedPayment;
