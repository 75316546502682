export default expiry => {
	const expString = expiry.replace(/\//g, '');
	if (!/^[0-9]+$/g.test(expString)) {
		// Not a number
		return expiry;
	}

	const block1 = expString.substr(0, 2);
	const block2 = expString.substr(2, 2);

	return [block1, block2].filter(Boolean).join('/');
};
