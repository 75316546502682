import React from 'react';

const InputShape = ({ value = '', required = true, valid = false, errors = [], ref = React.createRef() }) => ({
	value,
	valid,
	errors,
	required,
	ref,
});

export default InputShape;
