import React from 'react';
import PropTypes from 'prop-types';

import Text from '@exploreshare/everest/base/Text';
import styles from './SummaryInfo.module.css';

const SummaryInfo = ({ title, description }) => (
	<div className={styles.container}>
		<div className={styles.title}>
			<Text size="11px" bold>
				{title.toUpperCase()}
			</Text>
		</div>
		<div className={styles.description}>
			<Text color="grey">{description}</Text>
		</div>
	</div>
);

SummaryInfo.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
};

export default SummaryInfo;
