export default [
	'Afghanistan',
	'Albania',
	'Algeria',
	'Andorra',
	'Angola',
	'Anguilla',
	'Antigua & Barbuda',
	'Argentina',
	'Armenia',
	'Aruba',
	'Australia',
	'Austria',
	'Azerbaijan',
	'Bahamas',
	'Bahrain',
	'Bangladesh',
	'Barbados',
	'Belarus',
	'Belgium',
	'Belize',
	'Benin',
	'Bermuda',
	'Bhutan',
	'Bolivia',
	'Bosnia & Herzegovina',
	'Botswana',
	'Brazil',
	'British Virgin Islands',
	'Brunei',
	'Bulgaria',
	'Burkina Faso',
	'Burundi',
	'Cambodia',
	'Cameroon',
	'Cape Verde',
	'Cayman Islands',
	'Chad',
	'Chile',
	'China',
	'Colombia',
	'Congo',
	'Cook Islands',
	'Costa Rica',
	'Cote D Ivoire',
	'Croatia',
	'Cruise Ship',
	'Cuba',
	'Cyprus',
	'Czech Republic',
	'Denmark',
	'Djibouti',
	'Dominica',
	'Dominican Republic',
	'Ecuador',
	'Egypt',
	'El Salvador',
	'Equatorial Guinea',
	'Estonia',
	'Ethiopia',
	'Falkland Islands',
	'Faroe Islands',
	'Fiji',
	'Finland',
	'France',
	'French Polynesia',
	'French West Indies',
	'Gabon',
	'Gambia',
	'Georgia',
	'Germany',
	'Ghana',
	'Gibraltar',
	'Greece',
	'Greenland',
	'Grenada',
	'Guam',
	'Guatemala',
	'Guernsey',
	'Guinea',
	'Guinea Bissau',
	'Guyana',
	'Haiti',
	'Honduras',
	'Hong Kong',
	'Hungary',
	'Iceland',
	'India',
	'Indonesia',
	'Iran',
	'Iraq',
	'Ireland',
	'Isle of Man',
	'Israel',
	'Italy',
	'Jamaica',
	'Japan',
	'Jersey',
	'Jordan',
	'Kazakhstan',
	'Kenya',
	'Kuwait',
	'Kyrgyz Republic',
	'Laos',
	'Latvia',
	'Lebanon',
	'Lesotho',
	'Liberia',
	'Libya',
	'Liechtenstein',
	'Lithuania',
	'Luxembourg',
	'Macau',
	'Macedonia',
	'Madagascar',
	'Malawi',
	'Malaysia',
	'Maldives',
	'Mali',
	'Malta',
	'Mauritania',
	'Mauritius',
	'Mexico',
	'Moldova',
	'Monaco',
	'Mongolia',
	'Montenegro',
	'Montserrat',
	'Morocco',
	'Mozambique',
	'Namibia',
	'Nepal',
	'Netherlands',
	'Netherlands Antilles',
	'New Caledonia',
	'New Zealand',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'Norway',
	'Oman',
	'Pakistan',
	'Palestine',
	'Panama',
	'Papua New Guinea',
	'Paraguay',
	'Peru',
	'Philippines',
	'Poland',
	'Portugal',
	'Puerto Rico',
	'Qatar',
	'Reunion',
	'Romania',
	'Russia',
	'Rwanda',
	'Saint Pierre & Miquelon',
	'Samoa',
	'San Marino',
	'Satellite',
	'Saudi Arabia',
	'Senegal',
	'Serbia',
	'Seychelles',
	'Sierra Leone',
	'Singapore',
	'Slovakia',
	'Slovenia',
	'South Africa',
	'South Korea',
	'Spain',
	'Sri Lanka',
	'St Kitts & Nevis',
	'St Lucia',
	'St Vincent',
	'St. Lucia',
	'Sudan',
	'Suriname',
	'Swaziland',
	'Sweden',
	'Switzerland',
	'Syria',
	'Taiwan',
	'Tajikistan',
	'Tanzania',
	'Thailand',
	"Timor L'Este",
	'Togo',
	'Tonga',
	'Trinidad & Tobago',
	'Tunisia',
	'Turkey',
	'Turkmenistan',
	'Turks & Caicos',
	'Uganda',
	'Ukraine',
	'United Arab Emirates',
	'United Kingdom',
	'United States',
	'Uruguay',
	'Uzbekistan',
	'Venezuela',
	'Vietnam',
	'Virgin Islands (US)',
	'Yemen',
	'Zambia',
	'Zimbabwe',
];

export const frCountries = [
	'Afghanistan',
	'Afrique du Sud',
	'Alaska',
	'Albanie',
	'Algérie',
	'Allemagne',
	'Andorre',
	'Angleterre',
	'Angola',
	'Anguilla',
	'Antarctique',
	'Antigue-et-Barbude',
	'Antilles',
	'Arabie Saoudite',
	'Arctique',
	'Argentine',
	'Arménie',
	'Aruba',
	'Aspen',
	'Australie',
	'Autriche',
	'Azerbaïdjan',
	'Bahamas',
	'Bahreïn',
	'Bangladesh',
	'Belgique',
	'Belize',
	'Bénin',
	'Bermudes',
	'Bhoutan',
	'Biélorussie',
	'Bolivie',
	'Bonaire, Saint-Eustache et Saba',
	'Bosnie-Herzégovine',
	'Botswana',
	'Brésil',
	'Brunei',
	'Bulgarie',
	'Burkina Faso',
	'Burundi',
	'Cambodge',
	'Cameroun',
	'Canada',
	'Cap-Vert',
	'Chili',
	'Chine',
	'Chypre',
	'Colombie',
	'Congo-Brazzaville',
	'Corée du Nord',
	'Corée du Sud',
	'Costa Rica',
	"Côte d'Ivoire",
	'Croatie',
	'Cuba',
	'Curaçao',
	'Danemark',
	'Djibouti',
	'Dominique',
	'Écosse',
	'Égypte',
	'Émirats arabes unis',
	'Équateur',
	'Érythrée',
	'Espagne',
	'Estonie',
	'Eswatini',
	'État de Palestine',
	'États-Unis',
	'Éthiopie',
	'Fidji',
	'Finlande',
	'France',
	'Gabon',
	'Gambie',
	'Géorgie',
	'Ghana',
	'Gibraltar',
	'Grèce',
	'Grenade',
	'Groenland',
	'Guadeloupe',
	'Guam',
	'Guatemala',
	'Guinée',
	'Guinée équatoriale',
	'Guinée-Bissau',
	'Guyana',
	'Guyane française',
	'Haïti',
	'Honduras',
	'Hong Kong',
	'Hongrie',
	'Île de Man',
	'Îles Caïmans',
	'Îles Cook',
	'Îles Féroé',
	'Îles Mariannes du Nord',
	'Îles Marshall',
	'Îles Salomon',
	'Îles Turques et Caïques',
	'Îles Vierges',
	'Îles Vierges américaines',
	'Îles Vierges britanniques',
	'Inde',
	'Indonésie',
	'Irak',
	'Iran',
	'Irlande',
	'Irlande du Nord',
	'Islande',
	'Israël',
	'Italie',
	'Jamaïque',
	'Japon',
	'Jersey',
	'Jordanie',
	'Kazakhstan',
	'Kenya',
	'Kirghizistan',
	'Kiribati',
	'Kosovo',
	'Koweït',
	'La Barbade',
	'Laos',
	'Lesotho',
	'Lettonie',
	'Liban',
	'Liberia',
	'Libye',
	'Liechtenstein',
	'Lituanie',
	'Luxembourg',
	'Macédoine du Nord',
	'Madagascar',
	'Malaisie',
	'Malawi',
	'Maldives',
	'Mali',
	'Malte',
	'Maroc',
	'Martinique',
	'Maurice',
	'Mauritanie',
	'Mexique',
	'Micronésie',
	'Moldavie',
	'Monaco',
	'Mongolie',
	'Monténégro',
	'Montserrat',
	'Mozambique',
	'Myanmar',
	'Namibie',
	'Nauru',
	'Népal',
	'Nicaragua',
	'Niger',
	'Nigeria',
	'Norvège',
	'Nouvelle-Calédonie',
	'Nouvelle-Zélande',
	'Oman',
	'Ouganda',
	'Ouzbékistan',
	'Pakistan',
	'Palau',
	'Panama',
	'Papouasie-Nouvelle-Guinée',
	'Paraguay',
	'Pays de Galles',
	'Pays-Bas',
	'Pérou',
	'Philippines',
	'Pologne',
	'Polynésie française',
	'Portugal',
	'Puerto Rico',
	'Qatar',
	'République centrafricaine',
	'République démocratique du Congo',
	'République dominicaine',
	'Réunion',
	'Roumanie',
	'Royaume-Uni',
	'Royaume-Uni',
	'Russie',
	'Rwanda',
	'Saint Barthélemy',
	'Saint Martin',
	'Saint-Kitts-et-Nevis',
	'Saint-Marin',
	'Saint-Vincent-et-les-Grenadines',
	'Sainte-Lucie',
	'Salvador',
	'Samoa',
	'Sao Tomé et Principe',
	'Sénégal',
	'Serbie',
	'Seychelles',
	'Sierra Leone',
	'Singapour',
	'Sint Eustatius et Saba',
	'Sint Maarten',
	'Slovaquie',
	'Slovénie',
	'Somalie',
	'Soudan',
	'Sri Lanka',
	'Sud-Soudan',
	'Suède',
	'Suisse',
	'Suriname',
	'Svalbard et Jan Mayen',
	'Syrie',
	'Tadjikistan',
	'Taïwan',
	'Tanzanie',
	'Tchad',
	'Tchéquie',
	'Thaïlande',
	'Timor-Leste',
	'Togo',
	'Tonga',
	'Trinité-et-Tobago',
	'Tunisie',
	'Turkménistan',
	'Turquie',
	'Tuvalu',
	'Ukraine',
	'Uruguay',
	'Vanuatu',
	'Venezuela',
	'Vietnam',
	'Yémen',
	'Zambie',
	'Zimbabwe',
];
