const breakpoints = {
	keys: ['xs', 'sm', 'md', 'lg', 'xl'],
	values: {
		xs: 0,
		sm: 600,
		md: 960,
		lg: 1280,
		xl: 1920,
	},
	unit: 'px',
	step: 5,
};

const theme = {
	spacing: (n, i = 4) => n * i,
	palette: {
		black: '#242f33',
		grey100: '#62757a',
		grey90: '#9da2a3',
		grey80: '#d3d3db',
		grey60: '#ebebf0',
		red: '#e60c0c',
		lightRed: '#d62b2b',
		veryLightRed: '#debdbd',
		green: '#05b74a',
		lightGreen: '#a0dbb7',
		blue: '#2077d5',
		lightBlue: '#a2ccfa',
		darkBlue: '#2d65a1',
		veryLightBlue: '#d9eafc',
		green2: '#006e90',
		lightGreen2: '#bdd6de',
		orange: '#e66c0c',
		lightOrange: '#ded0bd',
		iceBlue: '#e5f0f3',
		white: '#ffffff',
	},
	shape: {
		borderRadius: 4,
	},
	transitions: {
		easing: {
			easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
			easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
			easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
			sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
		},
		duration: {
			shortest: 150,
			shorter: 200,
			short: 250,
			standard: 300,
			complex: 375,
			enteringScreen: 225,
			leavingScreen: 195,
		},
	},
	icons: {
		size: {
			default: 24,
			sm: 24,
			md: 48,
			lg: 60,
		},
	},
	shadows: [
		'none',
		'0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)',
		'0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12)',
		'0px 1px 8px 0px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 3px 3px -2px rgba(0,0,0,0.12)',
		'0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
		'0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
		'0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
		'0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
		'0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
		'0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
		'0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
		'0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
		'0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
		'0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
		'0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
		'0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
		'0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
		'0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
		'0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
		'0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
		'0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
		'0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
		'0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
		'0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
		'0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
	],
	typography: {
		fontSizeSm: 10,
		fontSize: 12,
		fontSizeMd: 14,
		fontSizeLg: 20,
		fontStyle: 'normal',
		fontStretch: 'normal',
		lineHeightSm: 1.5,
		lineHeight: 1.67,
		lineHeightMd: 1.71,
		lineHeightLg: 2,
		letterSpacing: 0.2,
		fontWeight: 400,
		htmlFontSize: 16,
		fontFamily: [
			'Open Sans',
			'-apple-system',
			'BlinkMacSystemFont',
			'Segoe UI',
			'Roboto',
			'Oxygen',
			'Ubuntu',
			'Cantarell',
			'Fira Sans',
			'Droid Sans',
			'Helvetica Neue',
			'sans-serif',
		].join(','),
		fontWeightLight: 300,
		fontWeightMedium: 500,
		fontWeightBold: 600,
		h1: {
			fontSize: 32,
			fontWeight: 600,
			lineHeight: 1.5,
			letterSpacing: 'normal',
		},
		h2: {
			fontSize: 20,
			fontWeight: 600,
			fontStyle: 'normal',
			fontStretch: 'normal',
			lineHeight: 1.6,
			letterSpacing: 'normal',
		},
		h3: {
			fontSize: 14,
			fontWeight: 400,
			fontStyle: 'normal',
			fontStretch: 'normal',
			lineHeight: 1.71,
			letterSpacing: 'normal',
		},
		h4: {
			fontSize: 12,
			fontWeight: 600,
			fontStyle: 'normal',
			fontStretch: 'normal',
			lineHeight: 1.5,
			letterSpacing: 'normal',
		},
		h5: {},
		h6: {},
		p: {},
		span: {},
	},
	breakpoints: {
		keys: breakpoints.keys,
		values: breakpoints.values,
		unit: breakpoints.unit,
		step: breakpoints.step,
		up: key => {
			const value = typeof breakpoints.values[key] === 'number' ? breakpoints.values[key] : key;
			return '@media (min-width:'.concat(value).concat(breakpoints.unit, ')');
		},
		down: key => {
			const endIndex = breakpoints.keys.indexOf(key) + 1;
			const upperbound = breakpoints.values[breakpoints.keys[endIndex]];
			if (endIndex === breakpoints.keys.length) return theme.breakpoints.up('xs');
			const value = typeof upperbound === 'number' && endIndex > 0 ? upperbound : key;
			return '@media (max-width:'.concat(value - breakpoints.step / 100).concat(breakpoints.unit, ')');
		},
		between: (start, end) => {
			const endIndex = breakpoints.keys.indexOf(end) + 1;
			if (endIndex === breakpoints.keys.length) return theme.breakpoints.up(start);
			return (
				'@media (min-width:'.concat(breakpoints.values[start]).concat(breakpoints.unit, ') and ') +
				'(max-width:'
					.concat(breakpoints.values[breakpoints.keys[endIndex]] - breakpoints.step / 100)
					.concat(breakpoints.unit, ')')
			);
		},
	},
};

export default theme;
