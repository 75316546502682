import React from 'react';
import PropTypes from 'prop-types';

import GenericItem from '../GenericItem';

const SimpleItem = ({ title, actionName, actionHandler, description }) => (
	<GenericItem {...{ title, actionName, actionHandler }}>{description}</GenericItem>
);

SimpleItem.propTypes = {
	title: PropTypes.string,
	actionName: PropTypes.string,
	actionHandler: PropTypes.func,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default SimpleItem;
