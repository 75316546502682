function analytics(...params) {
	if (window && typeof window.ga === 'function') {
		window.ga(...params);
	}
}

export default {
	pageView: () => analytics('send', 'event', 'Request', 'Review Details', { nonInteraction: true }),
	emailLoaded: () => analytics('send', 'event', 'Request', 'Email', { nonInteraction: false }),
	firstNameLoaded: () => analytics('send', 'event', 'Request', 'FirstName', { nonInteraction: false }),
	lastNameLoaded: () => analytics('send', 'event', 'Request', 'LastName', { nonInteraction: false }),
	phoneLoaded: () => analytics('send', 'event', 'Request', 'Phone', { nonInteraction: false }),
	validThruLoaded: () => analytics('send', 'event', 'Request', 'Valid', { nonInteraction: false }),
	cardNumberLoaded: () => analytics('send', 'event', 'Request', 'CardNumber', { nonInteraction: false }),
	cvvLoaded: () => analytics('send', 'event', 'Request', 'CVV', { nonInteraction: false }),
	billingCountryLoaded: country =>
		analytics(
			'send',
			'event',
			'Request', // CATEGORY
			'CVV', // ACTION
			country, // LABEL
			{ nonInteraction: false }
		),
	onRequest: tripId =>
		analytics('send', 'event', {
			eventCategory: 'Request',
			eventAction: 'Submit',
			eventLabel: tripId,
			nonInteraction: false,
		}),
};
