import React from 'react';
import { uuid, getIn, CREDIT_CARD_FEE } from '../../utils';

export const getFinalPrice = ({ booking }) => {
	const [minDuration, maxDuration] = booking.trip.duration;
	if (minDuration === maxDuration) {
		return booking.price_per_person * booking.people_count;
	}
	return booking.price_per_person * booking.people_count * booking.duration;
};

export const getFinalPriceWithFee = ({ booking }) => {
	const customerFee = booking.customer_fee || 0;
	const [minDuration, maxDuration] = booking.trip.duration;
	if (minDuration === maxDuration) {
		return Math.ceil((booking.price_per_person * booking.people_count) / ((100 - customerFee) / 100));
	}
	return Math.ceil((booking.price_per_person * booking.people_count * booking.duration) / ((100 - customerFee) / 100));
};

export const getCreditCardFee = ({ booking }, { distribution, method }) => {
	const finalPrice = getFinalPrice({ booking });
	if (!distribution || !method) return;
	const creditCardFee = (() => {
		if (distribution === 'total-now') {
			return finalPrice * (method === 'cc' ? CREDIT_CARD_FEE / 100 : 0); // 100% now
		}
    return finalPrice * 0.75 * (method === 'cc' ? CREDIT_CARD_FEE / 100 : 0); // 25% now and 75% later
	})();
	return creditCardFee;
};

export const getAvaliability = reducer => reducer.booking.availability || [];
export const getWhatsIncluded = reducer => reducer.booking.trip.whats_included || [];
export const getTitle = reducer => reducer.booking.trip.title || '';
export const getCoverURL = reducer => reducer.booking.trip.cover_url || '';
export const getLevel = reducer => reducer.booking.trip.level || '';
export const getSkillLevel = reducer => reducer.booking.trip.skill_level || '';
export const getFitnessLevel = reducer => reducer.booking.trip.fitness_level || '';
export const getDaysCount = reducer => (reducer.booking.duration > 1 ? 'days' : 'day');
export const generateTravellers = booking => {
	const travellers = (() => {
		if (booking.travellers.length === 0) {
			if (booking.people_count > 1) {
				return Array.from(Array(booking.people_count - 1), () => ({
					id: uuid(),
					firstName: {
						value: '',
						valid: false,
						errors: [],
						required: false,
						ref: React.createRef(),
					},
					lastName: {
						value: '',
						valid: false,
						errors: [],
						required: false,
						ref: React.createRef(),
					},
					email: {
						value: '',
						valid: false,
						errors: [],
						required: true,
						ref: React.createRef(),
					},
					phone: { required: false },
					valid: false,
				}));
			}
			return [];
		}
		return booking.travellers.map(traveller => ({
			id: traveller._id,
			firstName: {
				value: traveller.first_name,
				valid: true,
				errors: [],
				required: false,
				ref: React.createRef(),
			},
			lastName: {
				value: traveller.last_name,
				valid: true,
				errors: [],
				required: false,
				ref: React.createRef(),
			},
			email: {
				value: traveller.email,
				valid: true,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			phone: {
				value: '',
				valid: false,
				errors: [],
				required: false,
				ref: React.createRef(),
			},
			valid: true,
		}));
	})();
	return travellers;
};

export const generateLead = booking => {
	const firstName = getIn(booking.lead, ['first_name'], '');
	const lastName = getIn(booking.lead, ['last_name'], '');
	const email = getIn(booking.lead, ['email'], '');
	const phone = getIn(booking.lead, ['phone'], '');

	return {
		firstName: {
			value: firstName,
			valid: !!firstName,
			errors: [],
			required: true,
			ref: React.createRef(),
		},
		lastName: {
			value: lastName,
			valid: !!lastName,
			errors: [],
			required: true,
			ref: React.createRef(),
		},
		email: {
			value: email,
			valid: !!email,
			errors: [],
			required: true,
			ref: React.createRef(),
		},
		phone: {
			value: phone,
			valid: !!phone,
			errors: [],
			required: true,
			ref: React.createRef(),
		},
		valid: !!firstName && !!lastName && !!email && !!phone,
	};
};

export const extractValues = obj => {
	const snake = value =>
		value
			.replace(/\.?([A-Z])/g, function(x, y) {
				return `_${y.toLowerCase()}`;
			})
			.replace(/^_/, '');
	return Object.keys(obj).reduce((memo, key) => {
		if (!obj[key].value) return memo;
		return Object.assign(memo, { [snake(key)]: obj[key].value });
	}, {});
};

export const isValidBooking = booking => {
	// eslint-disable-next-line camelcase
	const { state, guide_state } = booking;
	// eslint-disable-next-line camelcase
	return state === 'B:REQUESTED' && guide_state !== 'G:DECLINED';
};

export const isPassthroughBooking = booking => {
	// eslint-disable-next-line camelcase
	const { state, guide_state: guideState } = booking;
	// eslint-disable-next-line camelcase
	return ['B:PAID', 'B:COMPLETED'].includes(state) && guideState !== 'G:DECLINED';
};

export const areTravellersValid = travellers =>
	travellers && travellers.reduce((isValid, traveller) => isValid && traveller.valid, true);
