import * as types from './types';
import initialState from '../../initialState';

const handlers = {
	[types.SET_PAYMENT_DISTRIBUTION]: (state, distribution) => ({ ...state, distribution }),
	[types.LOCK_PAYMENT_DISTRIBUTION]: (state, lock) => ({ ...state, lock }),
	[types.SET_PAYMENT_METHOD]: (state, method) => ({ ...state, method }),
};

export default (state = initialState.bookingReducer, { type, payload }) => {
	const handler = handlers[type];
	return handler ? handler(state, payload) : state;
};
