/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// import TagManager from 'react-gtm-module';
import * as Sentry from '@sentry/browser';
import getStore from './getStore';
import initialState from './initialState';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { gtmId, sentryUrl, projectName, projectVersion, env } from './config';

export const store = getStore(initialState);
// TagManager.initialize({ gtmId,dataLayerName: 'es-checkout' });
if (['production', 'staging'].includes(env)) {
	Sentry.init({
		dsn: sentryUrl,
		release: `${projectName}@${projectVersion}`,
		environment: env,
		beforeSend: event => event,
	});
}

const script = document.createElement('script')
script.innerHTML = `window.dataLayer = window.dataLayer || [];
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`
document.head.insertBefore(script, document.head.childNodes[0])


ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
