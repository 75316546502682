import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import Header from '@exploreshare/everest/base/Header';

import styles from './BookingDetails.module.css';
import Heading from '../Commons/Heading';
import DateItem from '../Commons/Items/DateItem';
import SimpleItem from '../Commons/Items/SimpleItem';
import ImportantItem from '../Commons/Items/ImportantItem';
import { skillLevelDescriptions, fitnessDescriptions } from './FitnessDescriptions';
import useI18n from '../../i18n/usei18n';

const BookingDetails = ({
	availability,
	duration,
	finalPriceWithFee,
	currency,
	peopleCount,
	skillLevel,
	fitnessLevel,
	daysCount,
	openGroup,
}) => {
	const { t } = useI18n();

	return (
		<>
			<div className={styles.container}>
				<Header level={2}>{t('title')}</Header>
			</div>
			<div className={styles.container}>
				{availability && (
					<DateItem
						title={t('availability')}
						dates={availability.map(({ startDate, endDate }) => ({
							startDate: moment(startDate),
							endDate: moment(endDate),
						}))}
						canEdit={false}
					/>
				)}
				<SimpleItem title={t('price')} description={`${currency} ${finalPriceWithFee}`} />

				{!openGroup && <SimpleItem title={t('groupSize')} description={peopleCount} />}
				{openGroup && <SimpleItem title={t('partOfAGroup')} />}
			</div>
			<div className={styles.container}>
				<Heading size={4}>{t('important')}</Heading>
				<hr />
				{fitnessLevel && (
					<ImportantItem
						title={t('fitnessLevel')}
						subtitle={fitnessLevel}
						description={fitnessDescriptions(fitnessLevel, t)}
						icon="fitness"
					/>
				)}
				{skillLevel && (
					<ImportantItem
						title={t('skillLevel')}
						subtitle={skillLevel}
						description={skillLevelDescriptions(skillLevel, t)}
						icon="skill"
					/>
				)}
				{duration && daysCount && duration > 0 && (
					<ImportantItem
						title={t('departureDates')}
						subtitle={`${t('duration')}: ${duration} ${daysCount.replace(/day/, t('day'))}`}
						description={t('durationDescription', { duration, daysCount })}
						icon="calendar"
					/>
				)}
			</div>
		</>
	);
};
BookingDetails.propTypes = {
	availability: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			endDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			startDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		})
	),
	duration: PropTypes.number.isRequired,
	finalPriceWithFee: PropTypes.number.isRequired,
	currency: PropTypes.string.isRequired,
	peopleCount: PropTypes.number.isRequired,
	skillLevel: PropTypes.string,
	fitnessLevel: PropTypes.string,
	daysCount: PropTypes.string,
	openGroup: PropTypes.string,
};

export default BookingDetails;
