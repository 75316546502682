/* eslint-disable prettier/prettier */
import React from 'react';
import PropTypes from 'prop-types';

import Text from '@exploreshare/everest/base/Text';

const Heading = ({ size, children }) => {
	if (size === 1)
		return (
			<h1>
				<Text bold size="24px">
					{children}
				</Text>
			</h1>
		);
	if (size === 2)
		return (
			<h2>
				<Text bold size="20px">
					{children}
				</Text>
			</h2>
		);
	if (size === 3)
		return (
			<h3 style={{ margin: '16px 0' }}>
				<Text bold size="16px">
					{children}
				</Text>
			</h3>
		);
	if (size === 4)
		return (
			<h4 style={{ margin: '8px 0' }}>
				<Text bold size="12px">
					{children}
				</Text>
			</h4>
		);
	return (
		<h4 style={{ margin: '8px 0' }}>
			<Text bold size="12px">
				{children}
			</Text>
		</h4>
	);
};

Heading.propTypes = {
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	children: PropTypes.node,
};

export default Heading;
