const {
	REACT_ENV,
	REACT_APP_API_URL_STAGING,
	REACT_APP_API_URL_PROD,
	REACT_APP_STRIPE_PUBLIC_KEY_PROD,
	REACT_APP_STRIPE_PUBLIC_KEY_STAGING,
	REACT_APP_GTM_TRACKING_ID_STAGE,
	REACT_APP_GTM_TRACKING_ID_PROD,
	REACT_APP_SENTRY_KEY,
	REACT_APP_SENTRY_PROJECT,
	REACT_APP_LA2_URL_PROD,
	REACT_APP_LA2_URL_STAGING,
	APP_VERSION,
	APP_NAME,
} = process.env;

window.__version__ = APP_VERSION;

module.exports = {
	apiURL: REACT_ENV === 'production' ? REACT_APP_API_URL_PROD : REACT_APP_API_URL_STAGING,
	stripePublicKey: REACT_ENV === 'production' ? REACT_APP_STRIPE_PUBLIC_KEY_PROD : REACT_APP_STRIPE_PUBLIC_KEY_STAGING,
	gtmId: REACT_ENV === 'production' ? REACT_APP_GTM_TRACKING_ID_PROD : REACT_APP_GTM_TRACKING_ID_STAGE,
	sentryUrl: `https://${REACT_APP_SENTRY_KEY}@sentry.io/${REACT_APP_SENTRY_PROJECT}`,
	projectName: APP_NAME,
	projectVersion: APP_VERSION,
	env: REACT_ENV,
	la2Url: REACT_ENV === 'production' ? REACT_APP_LA2_URL_PROD : REACT_APP_LA2_URL_STAGING,
};
