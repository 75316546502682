import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Text from '@exploreshare/everest/base/Text';
import GenericItem from '../GenericItem';
import Modal from './Modal';
import Styles from './DetailItem.module.css';
import Icon from '../../Icon';

const DateItem = ({ title, dates, onChange, canEdit }) => {
	const [isOpen, setOpen] = useState(false);
	return (
		<>
			<GenericItem title={title} actionName="Edit" actionHandler={() => setOpen(true)} canEdit={canEdit}>
				<div>
					{dates.map(({ startDate, endDate }, i) =>
						startDate && endDate ? (
							<div key={i} className={Styles.containerRange}>
								{dates.length >= 2 && (
									<div>
										<Text bold color="#6f7676" size={11}>
											{i + 1}.&nbsp;&nbsp;
										</Text>
									</div>
								)}
								<div className={Styles.date}>
									<Text bold>{startDate.format('ll')}</Text>
								</div>
								<Icon name="continue" />
								<div className={Styles.date}>
									<Text bold>{endDate.format('ll')}</Text>
								</div>
							</div>
						) : (
							<Text key={i}>No available dates</Text>
						)
					)}
				</div>
			</GenericItem>
			{canEdit && (
				<Modal
					isOpen={isOpen}
					closeButtonHandler={() => setOpen(false)}
					title="Change availability"
					onChange={onChange}
				/>
			)}
		</>
	);
};

DateItem.propTypes = {
	title: PropTypes.string,
	onChange: PropTypes.func,
	canEdit: PropTypes.bool,
	dates: PropTypes.arrayOf(
		PropTypes.shape({
			startDate: PropTypes.object, // moment obj
			endDate: PropTypes.object, // moment object
		})
	),
};

export default DateItem;
