import React from 'react';

export default {
	app: {},
	bookingReducer: {
		booking: {
			trip: {
				duration: [],
			},
		},
		isFetching: true,
		error: false,
		success: false, // !important - this value should be false by default. Do not forget to change it
		postTravellersSuccess: false,
		isSubmitting: false,
		lead: {
			firstName: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			lastName: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			phone: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			email: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			valid: false,
		},
		travellers: [],
		payment: {
			firstName: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			lastName: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			phone: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			name: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			number: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			expiry: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			cvv: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			address: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			addressNumber: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			zip: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			city: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			country: {
				value: '',
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			acceptedTerms: {
				value: false,
				valid: false,
				errors: [],
				required: true,
				ref: React.createRef(),
			},
			valid: false,
		},
	},
};
