import React from 'react';
import PropTypes from 'prop-types';
import Wizard from '../Wizard';
import AllSet from './components/AllSet';
import ExtraDetails from './components/ExtraDetails';
import Error from './components/Error';
import { getQueryParams } from '../../utils';
import { la2Url } from '../../config';

const bookingId = getQueryParams().bookingId || sessionStorage.getItem('exploreshare:bookingId');
const token = getQueryParams().token || sessionStorage.getItem('exploreshare:token');

function goToStatusPage() {
	if (window) {
		window.location = `${la2Url}/status/${bookingId}?token=${token}`;
	}
}
const BookingSuccess = ({ coverUrl }) => {
	const [step, goToStep] = React.useState(1);
	return (
		<Wizard currentStep={step} goToStep={goToStep}>
			<AllSet stepKey={1} coverUrl={coverUrl} goToStatusPage={goToStatusPage} />
			<ExtraDetails stepKey={2} bookingId={bookingId} goToStatusPage={goToStatusPage} goToStep={goToStep} />
			<Error stepKey={3} goToStatusPage={goToStatusPage} goToStep={goToStep} />
		</Wizard>
	);
};

BookingSuccess.defaultProps = {
	coverUrl: 'https://source.unsplash.com/1600x900/?nature,mountain',
};

BookingSuccess.propTypes = {
	coverUrl: PropTypes.string,
};

export default BookingSuccess;
