export const frTable = {
	youAreOneStep:`Vous êtes un pas plus proche de votre aventure !`,
	thankYouWeSendYouAnEmail:`Merci. Nous vous avons envoyé un e-mail avec des instructions concernant votre demande.`,
	title: 'Vérifiez les détails de votre demande réservation',
	availability: 'Disponibilité',
	price: 'Prix',
	groupSize: 'Taille du groupe',
	partOfAGroup: "Vous ferez partie d'un groupe",
	important: 'Choses importantes à garder en tête',
	fitnessLevel: 'Niveau de fitness',
	skillLevel: "Niveau d'expérience",
	departureDates: 'Dates de départ',
	beginnerDesc: "Vous avez un peu d'expérience mais vous vous considérez comme un débutant",
	intermediateDesc:
		'Vous êtes confortable sur un terrain de jeu facile/intermédiaire mais vous savez que vous avez encore beaucoup à apprendre!',
	advancedDesc: 'Vous maîtrisez la discipline et vous êtes confortable sur tous types de terrain',
	openToEveryOne:
		"L'activité ne demande aucun effort considérable, donc n’importe qui peut participer quel que soit son niveau de fitness.",
	occasional:
		"L'activité est adaptée aux personnes qui font du sport occasionnellement comme: course à pied, cyclisme, natation, football, basketball etc, une fois ou deux par mois.",
	fit:
		"L'activité est adaptée aux personnes qui font du sport régulièrement (au moins deux fois par semaine) et qui peuvent supporter un effort moyen pendant une durée relativement longue (3 heures).",
	extremelyFit:
		'Cette activité demande des efforts importants. Elle est destinée aux personnes qui sont très bien équipées, qui s’entraînent au moins 3 fois par semaine avec une intensité élevée (par exemple: triathlon ou crossfit). Elle requiert également un niveaux d’endurance et de stamina relativement élevés.',
	duration: 'Durée',
	durationDescription:
		'Le guide choisira {{duration}}, {{daysCount}} dans la période que vous avez choisie. Vous pourrez modifier ces dates et discuter avec le guide après réservation.',
	day: 'jour',
	tripDays: "Duréé de l'activité",
	tripDaysTooltip:
		'Ces jours sont choisis par le guide endéans la période où vous êtes disponible. Vous pourrez toujours modifier cela plus tard, après la réservation, en accord avec le guide. Vous pouvez donc réserver maintenant.',
	travelers: 'Voyageurs',
	travelersPartOfGroup: 'Voyageurs\nVous rejoindrez un groupe constitué',
	pricePerPerson: 'Prix par personne',
	subtotal: 'Sous-total',
	creditCardFee: 'Frais de carte de crédit',
	bankTransferFee: 'Frais de virement bancaire',
	serviceFee: 'Frais de service',
	total: 'Total',
	payNow: 'Payer maintenant',
	payLater: 'Payer plus tard',
	whatsIncluded: 'Ce qui est inclus',
	guidedTrip: 'activité guidée',
	supportAndFaqs: 'Support et FAQs',
	freeCancellation: 'Annulation sans frais endéans les 48 heures après réservation',
	Continue: 'Continuer',
	'Continue to Payment': 'Continuer vers le paiement',
	Back: 'Retour',
	'Choose how would you like to pay': 'Choisissez comment vous souhaitez payer',
	'Select a payment method': 'Sélectionnez un mode de paiement',
	'Finish Booking': 'Fin de la réservation',
	backToCheckout: 'Retour au paiement',
	faq: 'Foire aux questions (FAQ)',
	send: 'Envoyer',
	enquiry: "Demande d'informations",
	enquiryPlaceholder: 'Posez votre question ici',
	lastName: 'Nom de famille',
	invalidLastName: 'Nom de famille invalide',
	firstName: 'Prénom',
	stillDoubts: 'Vous avez encore des doutes? Contactez nous',
	paymentInformation: 'Informations sur le paiement',
	confirmAndPay: 'Confirmez les détails et payez',
	Tips: 'Pourboires',
	Drinks: 'Boissons',
	'Visa Fee': 'Frais de visa',
	'Other Included': 'Autres inclus',
	'Hot Shower': 'Douche chaude',
	'Guiding Fee': 'Service du guide',
	Lunch: 'Repas du midi',
	Dinner: 'Repas du soir',
	Snacks: 'En-cas',
	'Group Porters': 'Porteurs pour le groupe',
	'Lifts Tickets': 'Billets de remontées mécaniques',
	'Local Flights': 'Vols locaux',
	'Group Equipment Rope': 'Equipement de groupe - Corde',
	'Group Equipment Skis': 'Equipement de groupe - Skis',
	Breakfast: 'Petit-déjeuner',
	'Mules And Horses': 'Mules et chevaux',
	'Group Eqmnt Chalk': 'Équipement de groupe - Magnésie',
	'Luggage Transfer': 'Transfert de bagages',
	'Personal Porters': 'Porteurs personnels',
	'Flight To The Start': 'Vol de départ',
	'Group Eqmnt Helmet': 'Equipement de groupe - Casque',
	'Group Eqmnt Ice Axe': 'Equipement de groupe - Piolet',
	'Camping Eqmnt Tents': 'Équipement de camping - Tentes',
	'Group Eqmnt Harness': 'Équipement de groupe - Beaudrier',
	'Personal Insurance': 'Assurance personnelle',
	'Cat Runs As Described': 'Cat-ski tel que décrit',
	'Group Eqmnt Crampons': 'Equipement de groupe - Crampons',
	'Group Eqmnt Ski Boots': 'Equipement de groupe - Chaussures de ski',
	'Group Eqmnt Ski Poles': 'Equipement de groupe - Bâtons de ski',
	'Clean Drinkable Water': 'Eau potable',
	'Group Eqmnt Crash Pads': 'Équipement de groupe - Crash pads',
	'Group Eqmnt Quickdraws': 'Équipement de groupe - Dégaines',
	'Accommodation Included': 'Hébergement inclus',
	'Group Eqmnt Hiking Shoes': 'Equipement de groupe - Chaussures de randonnée',
	'Mountain Guide Expenses': 'Frais de guide de montagne',
	'Permit And Entrance Fees': "Permis et droits d'entrée",
	'Transport During The Trip': 'Transport pendant le voyage',
	'Camping Eqmnt Sleeping Bag': 'Matériel de camping - Sac de couchage',
	'Camping Eqmnt Sleeping Pad': 'Matériel de camping - Matelas',
	'Group Eqmnt Climbing Shoes': "Equipement de groupe - Chaussures d'escalade",
	'Group Eqmnt Trekking Poles': 'Equipement de groupe - Bâtons de marche',
	'Helicopter Drops As Described': 'Déposes en hélicoptère comme décrit',
	'Transportation Start And Back': 'Transport aller-retour',
	'Camping Eqmnt Cooking Utensils': 'Matériel de camping - Ustensiles de cuisine',
	'Group Eqmnt Avalanche Equipment': "Equipement de groupe - Matériel d'avalanche",
	'Group Eqmnt Mountaineering Boots': "Equipement de groupe - Chaussures d'alpinisme",
	'Group Eqmnt Belay And Rappel Devices': "Equipement de groupe - Dispositifs d'assurage et de rappel",
  "Meals Breakfast": "Petit-déjeuner",
  "Meals Dinner": "Dîner",
};
