import React from 'react';
import PropTypes from 'prop-types';

import Text from '@exploreshare/everest/base/Text';
import styles from './InfoModal.module.css';

const InfoModal = ({ title, children }) => (
	<div className={styles.container}>
		<div className={styles.title}>
			<Text bold size="16px" color="white">
				{title}
			</Text>
		</div>
		{children}
	</div>
);

InfoModal.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
};

export default InfoModal;
