// Source: https://stackoverflow.com/a/53069683

export const getCreditCardBrand = number => {
	const ccNumString = number.replace(/ /g, '');
	if (!/^[0-9]+$/g.test(ccNumString)) {
		// Not a number
		return 'unknown';
	}

	// mc, starts with - 51 to 55
	// v, starts with - 4
	// dsc, starts with 6011, 622126-622925, 644-649, 65
	// amex, starts with 34 or 37
	let typeCheck = ccNumString.substring(0, 2);

	if (typeCheck.length === 2) {
		typeCheck = parseInt(typeCheck);
		if (typeCheck >= 40 && typeCheck <= 49) {
			return 'visa';
		}
		if (typeCheck >= 51 && typeCheck <= 55) {
			return 'mastercard';
		}
		if ((typeCheck >= 60 && typeCheck <= 62) || typeCheck === 64 || typeCheck === 65) {
			return 'discover';
		}
		if (typeCheck === 34 || typeCheck === 37) {
			return 'amex';
		}
	}
	return 'unknown';
};

// supports Amex, Master Card, Visa, and Discover
export const formatCreditCard = number => {
	const ccNumString = number.replace(/ /g, '');
	if (!/^[0-9]+$/g.test(ccNumString)) {
		// Not a number
		return number;
	}

	// mc, starts with - 51 to 55
	// v, starts with - 4
	// dsc, starts with 6011, 622126-622925, 644-649, 65
	// amex, starts with 34 or 37
	const cType = getCreditCardBrand(number);
	let block1 = '';
	let block2 = '';
	let block3 = '';
	let block4 = '';
	let block5 = '';

	// all support card types have a 4 digit firt block
	block1 = ccNumString.substring(0, 4);

	if (cType === 'visa' || cType === 'mastercard' || cType === 'discover') {
		// for 4X4 cards
		block2 = ccNumString.substring(4, 8);
		block3 = ccNumString.substring(8, 12);
		block4 = ccNumString.substring(12, 16);
		block5 = ccNumString.substring(16);
	} else if (cType === 'amex') {
		// for Amex cards
		block2 = ccNumString.substring(4, 10);
		block3 = ccNumString.substring(10, 15);
		block4 = ccNumString.substring(15);
	} else if (cType === 'unknown') {
		return number;
	}

	const formatted = [block1, block2, block3, block4, block5].join(' ').trim();
	return formatted;
};
