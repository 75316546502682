const translations = {
	fr: {
		availability: 'Disponibilité',
		groupSize: 'Nombre de personnes',
		price: 'Prix',
		errorTitle: 'Un problème est survenu lors de votre paiement',
		errorDesc:
			'Veuillez vérifier que les informations que vous avez saisies sont correctes ou vérifiez le statut de la carte auprès de votre banque.',
		goBackToCheckout: 'Retour à la réservation',
		address: 'Adresse',
		addressNumber: 'Numéro',
		zipCode: 'Code postal',
		city: 'Ville',
		country: 'Pays',
		selectACountry: 'Sélectionner un pays...',
		secureCardInfo: 'Sécuriser les informations relatives à la carte de crédit',
		nameOnCard: 'Nom sur la carte',
		cardNumber: 'Numéro de carte',
		expirationDate: 'Date d’expiration',
		securityCode: 'Code de sécurité',
		youWillPreAuthorize: 'Vous pré-autorisez un montant de',
		wontBeChargedYet:
			'Ne vous inquiétez pas ! Vous ne serez pas encore débité. Vous serez débité une fois que le guide aura confirmé sa disponibilité.',
		asPerToRemainingBalance: 'Pour le solde restant',
		automaticallyCharged:
			'il sera automatiquement débité de votre carte 45 jours avant la date de début de votre activité / séjour.',
		haveToTransfer: 'vous devrez nous transférer les fonds selon les instructions ci-dessous.',
		personalInformation: 'Informations personnelles',
		firstName: 'Prénom',
		lastName: 'Nom de famille',
		phone: 'Téléphone',
		phoneNumber: 'Numéro de téléphone',
		instructionsForBankTransfer: 'Instructions pour le virement bancaire',
		payeeName: 'Nom du bénéficiaire',
		reference: 'Référence',
		ach: 'ACH',
		accountNumber: 'Numéro de compte',
		type: 'Type',
		wireNumber: 'Numéro du transfer',
		bankAddress: 'Adresse bancaire',
		localUsTransfer: 'Virement local US',
		checkings: 'Chèques',
		international: 'International',
		US: "États-Unis d'Amérique",
		bankCode: 'code de la banque',
		belgium: 'Belgique',
		pay75daysLater: (currency, totalAmountToChargeLater) =>
			`Pour ce qui est du solde restant (${currency} ${totalAmountToChargeLater}) vous devrez nous le transférer selon les instructions ci-dessus. Vous devez le faire au plus tard 45 jours avant la date de début de votre voyage, sinon le voyage sera annulé`,
		wireNotice:
			"Si vous choisissez de payer par virement bancaire, votre réservation ne sera pas confirmée tant que vous n'aurez pas envoyé le reçu du paiement ici, via notre système de gestion des réservations. Vous recevrez un courriel contenant des instructions sur la manière de procéder.",
		payInFull: 'Payer en totalité',
		payLessUpfront: 'Payez moins au départ',
		ccNowRestLater: 'avec la carte de crédit maintenant, le reste plus tard',
		howPaymentWillOccur: 'Comment le paiement sera effectué:',
		youHaveChosen: 'Vous avez choisi:',
		choosePaymentMethod: 'Choisissez votre mode de paiement',
		choosePaymentMethodForRemaining: 'Choisissez votre mode de paiement pour le montant restant',
		forRemainingAmount: 'Pour le montant restant',
		chooseWheterToPay: 'choisir de payer par carte de crédit ou par virement bancaire.',
		creditCard: 'Carte de crédit',
		charged45DaysBeforeDP: 'sera prélevé 45 jours avant votre départ',
		youWillPayATotal: 'Vous paierez un total de',
		bankTransfer: 'Virement bancaire',
		weWillProvideInstructions: 'Nous vous fournirons des instructions détaillées',
		readAndAccept: "J'ai lu et j'accepte les",
		termsAndConditions: "Conditions d'utilisation",
		paymentError: 'Erreur de paiement',
		billingInformation: 'Informations sur la facturation',
		securePaymentInfo: 'Informations sur les paiements sécurisés',
		howToPay: 'Comment voulez-vous payer ?',
		changePaymentMethod: 'Modifier le mode de paiement',
		changePayment: 'Modifier le paiement',
		confirmAndPay: 'Confirmez les détails et payez',
		paymentInfo: 'Information sur le paiement',
	},
	en: {
		availability: 'Availability',
		groupSize: 'Group size',
		price: 'Price',
		errorTitle: 'Something went wrong with your payment',
		errorDesc: 'Please, check if the information you entered is correct, or check the card status with your bank.',
		goBackToCheckout: 'Go back to checkout',
		address: 'Address',
		addressNumber: 'Address number',
		zipCode: 'Post/ZIP code',
		city: 'City',
		country: 'Country',
		selectACountry: 'Select a country...',
		secureCardInfo: 'Secure credit card information',
		nameOnCard: 'Name on card',
		cardNumber: 'Card number',
		expirationDate: 'Expiration date',
		securityCode: 'Security code',
		youWillPreAuthorize: 'You will pre-authorize a charge of',
		wontBeChargedYet:
			"Don't worry! You won't be charged yet. You will be charged once the guide confirms his availability.",
		asPerToRemainingBalance: 'As per to the remaining balance',
		automaticallyCharged: 'it will be automatically charged to your card 45 days prior to your tour start date.',
		haveToTransfer: 'you will have to transfer to us according to the instructions below.',
		personalInformation: 'Personal information',
		firstName: 'First Name',
		lastName: 'Last Name',
		phone: 'Phone',
		phoneNumber: 'Phone Number',
		instructionsForBankTransfer: 'Instructions for bank transfer',
		payeeName: 'Payee Name',
		reference: 'Reference',
		ach: 'ACH routing number',
		accountNumber: 'Account number',
		type: 'Type',
		wireNumber: 'Wire Number',
		bankAddress: 'Bank address',
		localUsTransfer: 'Local US Transfers',
		checkings: 'Checkings',
		international: 'International',
		US: 'United States of America',
		bankCode: 'Bank code',
		belgium: 'Belgium',
		pay75daysLater: (currency, totalAmountToChargeLater) =>
			`As per to the remaining balance (${currency} ${totalAmountToChargeLater}) you will have to transfer to us according to the instructions above. You must do so no later than 45 days before your tour start date, otherwise the tour will be canceled.`,
		wireNotice:
			'If you choose to pay by bank transfer, your booking will not be confirmed until you send the receipt of payment via our booking management system. You will receive an email with instructions on how to do this.',
		payInFull: 'Pay in full',
		payLessUpfront: 'Pay less upfront',
		ccNowRestLater: 'with credit card now, the rest later',
		howPaymentWillOccur: 'How payment will occur:',
		youHaveChosen: 'You have chosen:',
		choosePaymentMethod: 'Choose your payment method',
		choosePaymentMethodForRemaining: 'Choose your payment method for the remaining amount',
		forRemainingAmount: 'For the remaining amount',
		chooseWheterToPay: 'choose whether to pay by credit card or bank transfer.',
		creditCard: 'Credit card',
		charged45DaysBeforeDP: 'will be charged 45 days before your departure',
		youWillPayATotal: 'You will pay a total of',
		bankTransfer: 'Bank transfer',
		weWillProvideInstructions: 'We will provide detailed instructions',
		readAndAccept: "I've read and accept the",
		termsAndConditions: 'Terms & Conditions of Use',
		paymentError: 'Payment error',
		billingInformation: 'Billing Information',
		securePaymentInfo: 'Secure payment information',
		howToPay: 'How would you like to pay?',
		changePaymentMethod: 'Change payment method',
		changePayment: 'Change payment',
		confirmAndPay: 'Confirm details and pay',
		paymentInfo: 'Payment Information',
	},
};

export default translations;
