/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import Styles from './SectionItem.module.css';

const SectionItem = ({ title, children, actionName, actionHandler, canEdit }) => (
	<div className={Styles.container}>
		<div className={Styles.title}>{title}</div>
		<div className={Styles.desc}> {children}</div>
		{canEdit && (
			<div className={Styles.action} onClick={actionHandler}>
				{actionName}
			</div>
		)}
	</div>
);

SectionItem.propTypes = {
	title: PropTypes.string,
	children: PropTypes.node,
	actionName: PropTypes.string,
	actionHandler: PropTypes.func,
	canEdit: PropTypes.bool,
};

export default SectionItem;
