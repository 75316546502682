import React from 'react';
import injectSheet from 'react-jss';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import * as Sentry from '@sentry/browser';
import Grid from '@exploreshare/everest/base/Grid';
import Text from '@exploreshare/everest/base/Text';
import Button from '@exploreshare/everest/base/Button';
import Input from '@exploreshare/everest/base/Input';
import Arrow from '@exploreshare/everest/icons/BackArrow';
import API from '../../../utils/api';
import { skillLevelDescriptions } from '../../BookingDetails/FitnessDescriptions';

const styles = theme => ({
	root: {
		maxWidth: theme.breakpoints.values.sm,
		margin: '20px auto 0',
	},
	optionsList: {
		display: 'flex',
	},
	skillBtn: {
		color: theme.palette.grey100,
		textTransform: 'uppercase',
		fontSize: 11,
		padding: '5px 10px',
		border: `1px solid transparent`,
		transition: 'all ease-out .4s',
		backgroundColor: theme.palette.white,
		marginRight: theme.spacing(2),
	},
	activeSkill: {
		color: theme.palette.black,
		borderColor: theme.palette.black,
	},
	submitBtn: {
		width: 187,
	},
});

class ExtraDetails extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			skillLevel: 'beginner',
			comments: '',
			goals: '',
			isLoading: false,
		};
	}

	setSkillLevel = e => {
		this.setState({ skillLevel: e.target.name });
	};

	changeField = e => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	};

	formIsValid = () => {
		const { skillLevel, goals, isLoading } = this.state;
		if (!skillLevel) return false;
		if (!/([^\s])/g.test(goals)) return false;
		if (isLoading) return false;
		return true;
	};

	submit = async () => {
		const { skillLevel, goals, comments } = this.state;
		const { bookingId, goToStatusPage, goToStep } = this.props;
		this.setState({ isLoading: true });
		try {
			await API.patch(`/widget/bookings/essential-info/${bookingId}/update`, {
				skill: skillLevel,
				skill_comments: comments,
				goals,
			});
			goToStatusPage();
		} catch (err) {
			Sentry.captureException(err);
			this.setState({ isLoading: false });
			goToStep(3);
		}
	};

	goBack = () => {
		const { goToStep } = this.props;
		goToStep(1);
	};

	render() {
		const { classes } = this.props;
		const { skillLevel, comments, goals } = this.state;
		return (
			<Grid container spacing={2} className={classes.root}>
				<Grid item xs={12}>
					<Button secondary onClick={this.goBack}>
						<Arrow color="blue" size="small" style={{ marginRight: 4 }} />
						Go back
					</Button>
				</Grid>
				<Grid item xs={12}>
					<Text size={20} bold>
						Please, fill these questions so we can deliver the best experience to you.
					</Text>
				</Grid>
				<Grid item xs={12}>
					<Text size={14} bold>
						What’s your skill level?
					</Text>
				</Grid>
				<Grid item xs={12}>
					<Grid container>
						<Grid item noPaddingX className={classes.optionsList}>
							<Button
								onClick={this.setSkillLevel}
								name="beginner"
								hoverEffect={false}
								className={classNames(classes.skillBtn, skillLevel === 'beginner' && classes.activeSkill)}>
								Beginner
							</Button>
							<Button
								onClick={this.setSkillLevel}
								name="intermediate"
								hoverEffect={false}
								className={classNames(classes.skillBtn, skillLevel === 'intermediate' && classes.activeSkill)}>
								Medium
							</Button>
							<Button
								onClick={this.setSkillLevel}
								name="advanced"
								hoverEffect={false}
								className={classNames(classes.skillBtn, skillLevel === 'advanced' && classes.activeSkill)}>
								Experienced
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Text color="grey">{skillLevelDescriptions(skillLevel)}</Text>
				</Grid>
				<Grid item xs={12}>
					<Input
						handleChange={this.changeField}
						value={comments}
						label="Additional comments"
						optional
						multiline
						rows={4}
						placeholder="Write additional information you've may not have yet provided..."
						name="comments"
						isValid
					/>
				</Grid>
				<Grid item xs={12}>
					<Text size={14} bold>
						Which are your goals for this trip?
					</Text>
					<Input
						value={goals}
						handleChange={this.changeField}
						multiline
						rows={4}
						errorLabels={['This field can not be empty']}
						name="goals"
						placeholder="Write your goals for this trip..."
						isValid={/([^\s])/g.test(goals)}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button onClick={this.submit} primary className={classes.submitBtn} disabled={!this.formIsValid()}>
						Send
					</Button>
				</Grid>
			</Grid>
		);
	}
}

ExtraDetails.propTypes = {
	classes: PropTypes.object.isRequired,
	bookingId: PropTypes.string.isRequired,
	goToStatusPage: PropTypes.func.isRequired,
	goToStep: PropTypes.func.isRequired,
};

export default injectSheet(styles)(ExtraDetails);
