import React from 'react';
import Grid from '@exploreshare/everest/base/Grid';
import Link from '@exploreshare/everest/base/Link';
import Text from '@exploreshare/everest/base/Text';

const FrenchFaq = () => (
	<>
		<Grid item xs={12}>
			<Text bold size={14}>
				Que se passe-t-il si je dois annuler le voyage / l’activité en raison du coronavirus (COVID-19) ?
			</Text>
			<Text size={14} color="grey">
				Si vous devez annuler votre voyage / l’activité en raison du coronavirus (COVID-19), vous aurez la possibilité
				de le postposer à plus tard (sans frais supplémentaire)
				<br />
				<br />
				Si vous ne pouvez pas reporter votre voyage, vous recevrez un bon pour réserver n'importe quel circuit chez
				Explore-Share, valable pendant 18 mois à compter de la date de départ initiale du circuit que vous avez réservé.
				mois à compter de la date de départ initiale du circuit que vous avez réservé. Vous aurez également la
				possibilité de transférer ce bon à une autre personne. Ce bon correspondra au prix total de l'excursion que vous
				avez réservée et payée, moins les frais éventuels non payés. moins les éventuels frais non remboursables que le
				guide a payés pour organiser le voyage.
				<br />
				<br />
				La politique d'annulation spéciale ci-dessus s'appliquera si:
				<ol type="a">
					<li>
						<Text size={14} color="grey">
							Vous notifiez l'annulation plus de 30 jours à l'avance, ou{' '}
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							Les autorités du pays d'origine d'où vous partez ont fixé une interdiction de voyage internationale qui ne
							vous permettent pas de quitter votre pays, ou{' '}
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							Si les autorités du/des pays où se déroule votre Voyage ne vous permettent pas d'y entrer ou imposent une
							quarantaine obligatoire qui vous empêche d'effectuer le voyage à la date convenue.
						</Text>
					</li>
				</ol>
				<br />
				Dans le cas contraire, la politique d'annulation standard s'applique. Vous pouvez y accéder{' '}
				<Link to="https://www.explore-share.com/terms/" newTab>
					here
				</Link>{' '}
				(voir la section "Services fournis par E&S".).
				<br />
				<br />
				Dans certains cas, dans des circonstances particulières, Explore-Share peut être en mesure d'offrir un
				remboursement à la place. Le remboursement sera Le remboursement sera intégral, moins les éléments non
				remboursables mentionnés dans nos conditions générales (3 % du prix total de la 3 % du prix total du voyage en
				tant que frais administratifs, 3 % du prix total du voyage en tant que frais de service, les frais de carte de
				crédit s'ils s'applique, et les éventuelles dépenses non remboursables que le guide a payées pour organiser le
				circuit, qui seront détaillées par le guide). par le guide)
				<br />
				<br />
				Pour des informations plus détaillées sur nos politiques d'annulation exceptionnelles Covid-19, veuillez
				consulter la page suivante page:{' '}
				<Link to="https://www.explore-share.com/fr/coronavirus/" newTab>
					https://www.explore-share.com/fr/coronavirus/
				</Link>
				.
			</Text>
		</Grid>
		<Grid item xs={12}>
			<Text bold size={14}>
				Pourrai-je discuter de mon itinéraire / de l'adéquation du voyage avec mon guide avant de payer ?
			</Text>
			<Text size={14} color="grey">
				Oui. Après avoir demandé à réserver un voyage, vous aurez la possibilité de poser toutes les questions que vous
				souhaitez à votre guide avant que nous ne débitions votre carte de crédit.
			</Text>
		</Grid>
		<Grid item xs={12}>
			<Text bold size={14}>
				Que se passe-t-il si je dois annuler le voyage ?
			</Text>
			<Text bold size={14}>
				Si vous réservez votre voyage plus de 30 jours à l'avance, vous disposez d'un délai de 48 heures après la
				réservation pour annuler et recevoir un remboursement complet sans déduction. recevoir un remboursement complet
				sans aucune déduction.
			</Text>
			<Text size={14} color="grey">
				Après cette période, si vous décidez d'annuler, vous obtiendrez un remboursement de 100% (moins les frais) si
				l'annulation est jusqu'à 60 jours avant le début du voyage, un remboursement de 75 % (sans frais) si vous
				annulez entre 59 et 30 jours avant le 30 jours avant le début du voyage, et de 50 % (sans frais) si vous annulez
				entre 29 et 21 jours avant le début du voyage. le début du voyage. Si vous annulez moins de 21 jours avant le
				premier jour du circuit, vous ne recevrez pas de remboursement.
				<br />
				<br />
				Les frais non remboursables qui seront déduits du remboursement sont les suivants :
				<ul>
					<li>
						<Text size={14} color="grey">
							Frais d'administration : 3 % du prix total de l'excursion.
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							Frais de service : 3 % du prix total de la visite.
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							Les éventuels frais non remboursables que le guide a payés pour organiser le voyage (hébergement, frais
							d'administration, salaires, porteurs, nourriture, etc. d'administration, salaires, porteurs, nourriture,
							etc.) seront définis par le guide au moment de la demande d'annulation du client. d'annulation.
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							Frais de carte de crédit : environ 1,6 % du montant payé par carte de crédit.
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							Tout autre frais qui avait été clairement mentionné au client comme étant non remboursable avant que le
							client ne réserve.
						</Text>
					</li>
				</ul>
				<br />
			</Text>
			<Text size={14} color="grey">
				Si le mauvais temps ou des conditions inadéquates obligent le guide à annuler l'excursion, il : a) proposera une
				activité similaire à un autre endroit où les conditions sont meilleures ou b) proposera de reporter l'activité à
				plus tard. a) proposer une activité similaire dans un autre lieu où les conditions sont meilleures, ou b)
				proposer de reporter l'activité à une date ultérieure, ou c) annuler. date ultérieure, ou c) annuler. Si vous
				refusez l'option a), les conditions d'annulation s'appliqueront. Veuillez noter, toutefois, que la l'annulation
				d'un circuit en raison du mauvais temps ne peut être décidée que par le guide (et non par le client). Dans des
				circonstances circonstances exceptionnelles, le guide peut décider, à sa seule discrétion, de rembourser le
				client (moins les frais de transaction).
			</Text>
			<br />
			<Text size={14} color="grey">
				Tenez compte, toutefois, du fait que si l'hébergement, les vols ou les transferts étaient inclus dans le voyage
				que vous avez réservé, une politique différente peut s'appliquer, car le guide sera lié par la politique
				d'annulation du fournisseur.
			</Text>
			<br />
			<Text size={14} color="grey">
				Les 3% de frais de service à la clientèle d'Explore-Share payés au moment de la réservation ne sont pas
				remboursables. Il est conseillé d'avoir une police d'assurance qui couvre les annulations.
			</Text>
		</Grid>
		<Grid item xs={12}>
			<Text bold size={14}>
				Comment puis-je payer mon voyage ?
			</Text>
			<Text size={14} color="grey">
				Vous pouvez utiliser votre carte de crédit pour payer le voyage. Nous utilisons Stripe, un processeur de
				paiement largement réputé, pour pour garantir la sécurité de votre paiement.
			</Text>
		</Grid>
		<Grid item xs={12}>
			<Text bold size={14}>
				Quel équipement dois-je apporter ? Avez-vous une liste d'équipement ?
			</Text>
			<Text size={14} color="grey">
				Le guide sera heureux de vous aider à vous préparer pour votre aventure. Une fois votre réservation confirmée,
				il vous vous enverra tous les détails et une liste d'équipement si nécessaire. Il pourra également vous aider à
				trouver des locations si nécessaire.
			</Text>
		</Grid>
		<Grid item xs={12}>
			<Text bold size={14}>
				Pourquoi devrais-je réserver par l'intermédiaire d'Explore-Share ?
			</Text>
			<Text size={14} color="grey">
				L'offre la plus large au monde : Avec plus de 11 000 offres de voyages dans plus de 70 pays, nous sommes, sans
				conteste, la plus grande plateforme de réservation d'aventures au monde. plateforme de réservation d'aventures
				au monde. Notre communauté de plus de 1 700 guides expérimentés vous garantit l'expérience la plus sûre
				possible, mais aussi que vous rentrez chez vous avec un grand sourire. l'expérience la plus sûre possible, mais
				aussi que vous rentriez chez vous avec un grand sourire sur le visage. C'est un fait : l'aventure rend les gens
				les gens heureux.
				<br />
				<br />
				Soutenir l'économie locale : en engageant un guide local, vous ne ferez pas seulement ce que vous aimez et
				vivrez une expérience mémorable. mémorable ; vous soutiendrez également les économies locales et permettrez à de
				grands guides de vivre de leur passion. passion. Contrairement aux agences de voyage traditionnelles, près de
				85% de l'argent que vous dépensez sur Explore-Share ira aux guides et agences locales. guides et agences locaux.
				<br />
				<br />
				Votre argent est en sécurité : Nous faisons en sorte que vous puissiez envoyer de l'argent en toute sécurité aux
				guides locaux à l'autre bout du monde. Nous collectons et gardons votre argent jusqu'à 30 jours avant le début
				du voyage, en nous assurant que tout se passe bien. A processus sécurisé pour vous et pour le guide !
			</Text>
		</Grid>
	</>
);

export default FrenchFaq;
