import React from 'react';
import PropTypes from 'prop-types';

import Text from '@exploreshare/everest/base/Text';
import ProgressBar from './ProgressBar';
import styles from './Steps.module.css';

import { useWindowDimensions } from '../../WindowDimensionsProvider';

const Steps = ({ step, labels }) => {
	const { width } = useWindowDimensions();
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				<div className={styles.progressBar}>
					<ProgressBar currentStep={step} />
				</div>
				<div className={styles.labels}>
					{labels.map((label, index) => (
						<Text key={label} className={styles.label} size={width < 800 ? '10px' : '14px'} bold={index < step}>
							{width < 800 ? label : `${index + 1}. ${label}`}
						</Text>
					))}
				</div>
			</div>
		</div>
	);
};

Steps.propTypes = {
	step: PropTypes.number,
	labels: PropTypes.arrayOf(PropTypes.string),
};

export default Steps;
