import React, { createContext, useEffect, useState } from 'react';
import rosetta from 'rosetta';
import PropTypes from 'prop-types';
import moment from 'moment';
import { enTable } from './en';
import { frTable } from './fr';

const i18n = rosetta();

export const DEFAULT_LOCALE = 'en';
export const FR_LOCALE = 'fr';
export const languages = [FR_LOCALE, DEFAULT_LOCALE];

export const I18nContext = createContext();

export default function I18nContextProvider({ children }) {
	const [currentLocale, setCurrentLocale] = useState(DEFAULT_LOCALE);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const locale = queryParams.get('locale');
		setCurrentLocale(languages.includes(locale) ? locale : DEFAULT_LOCALE);
	}, []);

	const table = {
		en: enTable,
		fr: frTable,
	};

	i18n.locale(currentLocale);
	i18n.set(currentLocale, table[currentLocale]);

	moment.locale(currentLocale);

	const i18nWrapper = {
		currentLocale,
		t: (...args) => i18n.t(...args),
		table: () => table[currentLocale],
	};

	return <I18nContext.Provider value={{ ...i18nWrapper }}>{children}</I18nContext.Provider>;
}

I18nContextProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
