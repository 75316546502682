import React from 'react';
import PropTypes from 'prop-types';

import Text from '@exploreshare/everest/base/Text';
import PhoneInput from '@exploreshare/everest/base/PhoneInput';
import styles from './Input.module.css';

// const colors = {
// 	red: '#d0021b',
// 	black: '#242f33',
// 	blue: '#2077d5',
// 	grey: '#9da2a3',
// 	lightGrey: '#eaeaea',
// };

class Input extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			changed: false,
			touched: false,
		};
	}

	onBlur = e => {
		const { onBlur } = this.props;
		this.setState(prevState => ({ focused: !prevState.focused }));
		if (onBlur) return onBlur(e);
	};

	onChange = e => {
		const { onChange } = this.props;
		const { changed } = this.state;
		if (onChange) onChange(e);
		if (!changed) this.setState({ changed: true });
	};

	onFocus = e => {
		const { onFocus } = this.props;
		const { touched } = this.state;
		this.setState(prevState => ({ focused: !prevState.focused }));
		if (!touched) this.setState({ touched: true });
		if (onFocus) return onFocus(e);
	};

	validate = () => {
		this.setState({ touched: true, changed: true });
	};

	render = () => {
		const {
			label,
			placeholder,
			value,
			// disabled,
			onBlur,
			onChange = e => e,
			onFocus,
			optional,
			errorLabels = [<>&nbsp;</>],
			validate = false,
			valid = true,
			type = 'input',
			...rest
		} = this.props;
		const { changed, touched, focused } = this.state;

		const showErrorMessage = errorLabels.length && changed && touched && !focused && !valid;

		const inputStyles = (() => {
			const stylesArr = [styles.input];
			if (validate && valid) {
				stylesArr.push(styles.complete);
			}
			if (validate && !valid) {
				stylesArr.push(styles.error);
			}
			return stylesArr;
		})();

		// const phoneStyles = (() => {
		// 	const color = (() => {
		// 		if (validate) {
		// 			if (valid) return colors.black;
		// 			return colors.red;
		// 		}
		// 		return colors.grey;
		// 	})();

		// 	const input = {
		// 		width: '100%',
		// 		borderRadius: '4px',
		// 		border: `solid 1px ${color}`,
		// 		height: '38px',
		// 		backgroundColor: disabled ? colors.lightGrey : 'unset',
		// 	};
		// 	const button = {
		// 		borderRadius: '4px',
		// 		border: `solid 1px ${color}`,
		// 		borderTopRightRadius: 0,
		// 		borderBottomRightRadius: 0,
		// 	};

		// 	return { input, button };
		// })();

		const comp = (() => {
			if (type === 'phone') {
				return (
					<PhoneInput
						handleChange={({ number }) => this.onChange(number)}
						onBlur={this.onBlur}
						onFocus={this.onFocus}
						{...rest}
					/>
				);
			}
			return (
				<input
					className={inputStyles.join(' ')}
					placeholder={placeholder}
					value={value}
					onChange={e => onChange(e.target.value)}
					onBlur={onBlur}
					onFocus={onFocus}
					{...rest}
				/>
			);
		})();
		return (
			<div>
				<div>
					<Text className={styles.inline} size="10px">
						{label}
					</Text>
					{optional && (
						<Text className={styles.inline} size="10px" color="grey">
							&nbsp;(optional)
						</Text>
					)}
				</div>
				{/* <div className={inputStyles.join(' ')}> */}
				<div>{comp}</div>
				{showErrorMessage && errorLabels.length > 0 ? (
					errorLabels.map(error => (
						<div key={error}>
							<Text color="red">{!valid ? error : <>&nbsp;</>}</Text>
						</div>
					))
				) : (
					<div>
						<Text>
							<>&nbsp;</>
						</Text>
					</div>
				)}
			</div>
		);
	};
}

Input.defaultProps = {
	errorLabels: [<>&nbsp;</>],
};

Input.propTypes = {
	label: PropTypes.string,
	placeholder: PropTypes.string,
	value: PropTypes.string,
	onBlur: PropTypes.func,
	onChange: PropTypes.func,
	onFocus: PropTypes.func,
	errorLabels: PropTypes.arrayOf(PropTypes.string),
	validate: PropTypes.bool,
	valid: PropTypes.bool,
	optional: PropTypes.bool,
	disabled: PropTypes.bool,
	type: PropTypes.oneOf(['input', 'phone']),
};

export default Input;
