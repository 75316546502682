export const fitnessDescriptions = (key, t) => {
	const descriptions = {
		'open to everyone': t('openToEveryOne'),
		occasional: t('occasional'),
		fit: t('fit'),
		'extremely fit': t('extremelyFit'),
	};
	return descriptions[key.toLowerCase()];
};

export const skillLevelDescriptions = (key, t) => {
	const descriptions = {
		beginner: t('beginnerDesc'),
		intermediate: t('intermediateDesc'),
		advanced: t('advancedDesc'),
	};
	return descriptions[key.toLowerCase()];
};
