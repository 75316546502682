import React from 'react';
import PropTypes from 'prop-types';

import GenericInput from '../GenericInput';

const PhoneInput = ({ inputRef, ...rest }) => (
	<GenericInput {...rest} ref={inputRef} type="phone" label="Phone Number" />
);

PhoneInput.propTypes = {
	inputRef: PropTypes.oneOfType([
		// Either a function
		PropTypes.func,
		// Or the instance of a DOM native element (see the note about SSR)
		PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	]),
};

export default PhoneInput;
