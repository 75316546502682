import React from 'react';
import Text from '@exploreshare/everest/base/Text';
import Grid from '@exploreshare/everest/base/Grid';
import Container from '@exploreshare/everest/base/Container';
import Link from '@exploreshare/everest/base/Link';

export const BookingError = () => (
	<Grid container spacing={10}>
		<Grid item xs={12}>
			<Container>
				<Grid container spacing={2} justify="center">
					<Grid item xs={12}>
						<Text size={60} bold>
							Oops!
						</Text>
					</Grid>
					<Grid item xs={12}>
						<Text size="md">There was a mistake on our side. Please try again or send a message to support.</Text>
					</Grid>
					<Grid item xs={12}>
						<Link to="https://www.explore-share.com/">Go back to Home Page</Link>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	</Grid>
);
