import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import Button from '@exploreshare/everest/base/Button';
import Arrow from '@exploreshare/everest/icons/BackArrow';
import Grid from '@exploreshare/everest/base/Grid';
import Text from '@exploreshare/everest/base/Text';
import Header from '@exploreshare/everest/base/Header';
import Separator from '@exploreshare/everest/base/Separator';
import Input from '@exploreshare/everest/base/Input';
import Toast from '@exploreshare/everest/base/Toast';
import { validateContactUs } from '../../utils/validations';
import { sendFaqMessage } from '../../utils/api';
import InputShape from '../../utils/Shapes/inputShape';
import useI18n from '../../i18n/usei18n';
import EnglishFaq from './EnglishFaq';
import FrenchFaq from './FrenchFaq';

const styles = theme => ({
	root: {
		maxWidth: theme.breakpoints.values.sm,
	},
	backButton: {
		padding: '7px 14px',
	},
});

const Faq = ({ lead, toggleFaq, bookingId, classes }) => {
	const { t, currentLocale } = useI18n();

	const inputGroupInitialState = () => {
		const group = {
			firstName: InputShape({
				value: lead.firstName.value,
			}),
			lastName: InputShape({
				value: lead.lastName.value,
			}),
			enquiry: InputShape({}),
		};
		validateContactUs(group);
		return group;
	};

	const [inputGroup, setInputGroup] = useState(inputGroupInitialState);
	const [isSubmitting, setSubmitting] = React.useState(false);
	const [showToast, setShowToast] = React.useState({ value: false, type: '', label: '' });
	const { firstName, lastName, enquiry } = inputGroup;

	const handleChange = field => ({ target }) => {
		const group = { ...inputGroup, [field]: { ...inputGroup[field], value: target.value } };
		validateContactUs(group);
		setInputGroup(group);
	};
	const validateInput = input =>
		input.ref && input.ref.current && input.ref.current.validate && input.ref.current.validate();

	const showErrors = _inputGroup => Object.values(_inputGroup).forEach(validateInput);

	const submitValues = async () => {
		if (!inputGroup.valid) {
			showErrors(inputGroup);
			return;
		}
		setSubmitting(true);
		try {
			// post api
			await sendFaqMessage({
				bookingId,
				values: {
					first_name: firstName.value,
					last_name: lastName.value,
					enquiry: enquiry.value,
				},
			});

			// clear values
			setInputGroup(inputGroup);

			// notificate success
			setShowToast({
				value: true,
				type: 'correct',
				label: (
					<span>
						Your request was successfully sent!
						<Button type="text" onClick={toggleFaq}>
							Go back to checkout
						</Button>
					</span>
				),
			});
		} catch (err) {
			setShowToast({
				value: true,
				type: 'warning',
				label: 'There was an error sending your request',
			});
		} finally {
			setSubmitting(false);
		}
	};

	return (
		<>
			<Grid container spacing={4} justify="center">
				<Grid item xs={12} className={classes.root}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Button secondary onClick={toggleFaq} className={classes.backButton}>
								<Arrow color="blue" size={18} style={{ marginRight: 4 }} />
								{t('backToCheckout')}
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Header level={2}>{t('faq')}</Header>
						</Grid>

						{currentLocale === 'en' && <EnglishFaq />}
						{currentLocale === 'fr' && <FrenchFaq />}

						<Grid item xs={12}>
							<Grid container spacing={2}>
								<Grid noPaddingX item xs={12}>
									<Text bold uppercase size={14} style={{ marginLeft: 8, marginBottom: 4 }}>
										{t('stillDoubts')}
									</Text>
									<Separator />
								</Grid>
								<Grid className={classes.inputLeft} item xs={12} sm={6}>
									<Input
										label={t('firstName')}
										placeholder="Indiana"
										value={firstName.value}
										errorLabels={firstName.errors}
										handleChange={handleChange('firstName')}
										isValid={firstName.valid}
										ref={firstName.ref}
									/>
								</Grid>
								<Grid item xs={12} sm={6}>
									<Input
										label={t('lastName')}
										placeholder="Jones"
										value={lastName.value}
										errorLabels={[t('invalidLastName')]}
										handleChange={handleChange('lastName')}
										isValid={lastName.valid}
										ref={lastName.ref}
									/>
								</Grid>
								<Grid item xs={12}>
									<Input
										multiline
										rows={4}
										label={t('enquiery')}
										type="textarea"
										placeholder={t('enquiryPlaceholder')}
										value={enquiry.value}
										errorLabels={enquiry.errors}
										handleChange={handleChange('enquiry')}
										isValid={enquiry.valid}
										ref={enquiry.ref}
									/>
								</Grid>
								<Grid item xs={12}>
									<Button
										size={3}
										style={{ padding: '10px 85px' }}
										primary
										onClick={submitValues}
										disabled={isSubmitting}>
										{t('send')}
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{showToast.value && (
				<Toast
					type={showToast.type}
					label={showToast.label}
					onClose={() => setShowToast(prev => ({ ...prev, value: false }))}
				/>
			)}
		</>
	);
};

Faq.propTypes = {
	toggleFaq: PropTypes.func.isRequired,
	classes: PropTypes.object,
	lead: PropTypes.object,
	bookingId: PropTypes.string,
};

const mapStateToProps = ({ bookingReducer }) => ({
	lead: bookingReducer.lead,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(Faq));
