// It will return new object with no empty key values
// example:
// const obj = clean({  a: 'blue', b: '', c: false, d: true, e: {} })
// obj ===  { a: 'blue', c: false,  d: true, e: {}  }
export default obj => {
	const n = obj;
	// eslint-disable-next-line no-unused-vars
	for (const propName in n) {
		if (n[propName] === null || n[propName] === undefined || (typeof n[propName] === 'string' && !n[propName])) {
			delete n[propName];
		}
	}
	return n;
};
