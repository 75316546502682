import axios from 'axios';
import { getQueryParams } from './index';
import { apiURL } from '../config';

const token =
	(window.location.search && getQueryParams(window.location.search).token) ||
	(window && window.sessionStorage && window.sessionStorage.getItem('exploreshare:token')) ||
	'';

const API = axios.create({
	baseURL: apiURL,
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
		Authorization: `Bearer ${token}`,
	},
});

const getTrip = tripId => API.get(`/widget/trips/${tripId}`);
const getBooking = bookingId => API.get(`/status/bookings/${bookingId}`);
const createBooking = values => API.post('/widget/bookings/request', values);
const createToken = ({ bookingId, email, code }) => API.post(`/status/auth/booking/${bookingId}`, { email, code });
const sendFaqMessage = ({ bookingId, values }) => API.post(`/forms/${bookingId}/faq`, values);
const postGuideMessage = ({ bookingId, enquiry }) => API.post(`/widget/bookings/enquire/${bookingId}`, { enquiry });

export { API as default, getTrip, getBooking, createBooking, createToken, sendFaqMessage, postGuideMessage };
