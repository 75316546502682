import React from 'react';
import PropTypes from 'prop-types';

import exit from '../../../assets/img/exit.svg';
import check from '../../../assets/img/check.svg';
import continueImg from '../../../assets/img/continueArrow.svg';
import logo from '../../../assets/img/logo.svg';
import weight from '../../../assets/img/weight.svg';
import fitness from '../../../assets/img/fitness.svg';
import skill from '../../../assets/img/skill.svg';
import calendar from '../../../assets/img/calendar.svg';

const typeToImg = type => {
	switch (type) {
		case 'exit':
			return exit;
		case 'check':
			return check;
		case 'continue':
			return continueImg;
		case 'logo':
			return logo;
		case 'weight':
			return weight;
		case 'fitness':
			return fitness;
		case 'skill':
			return skill;
		case 'calendar':
			return calendar;
		default:
			return logo;
	}
};

const Icon = ({ name, size = '24px', className }) => (
	<img
		style={{ height: size, width: size, objectFit: 'contain' }}
		className={className}
		src={typeToImg(name)}
		alt={name}
	/>
);

Icon.propTypes = {
	name: PropTypes.string,
	size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	className: PropTypes.string,
};

export default Icon;
