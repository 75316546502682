import React from 'react';
import PropTypes from 'prop-types';

import styles from './ProgressBar.module.css';

const getStyle = step => {
	switch (step) {
		case 1:
			return styles.step1;
		case 2:
			return styles.step2;
		case 3:
			return styles.step3;
		default:
			return null;
	}
};

const ProgressBar = ({ currentStep }) => (
	<div className={styles.container}>
		<div className={[styles.background, getStyle(currentStep)].join(' ')}>
			<div className={styles.progressBar} />
		</div>
	</div>
);

ProgressBar.propTypes = {
	currentStep: PropTypes.number,
};
export default ProgressBar;
