export default input => {
	const ret = {};
	const search = input || window.location.search;

	if (typeof search !== 'string') {
		return ret;
	}
	const parsed = search.trim().replace(/^[?#&]/, '');
	if (!parsed) {
		return ret;
	}
	const pairs = parsed.split('&');
	for (let i = 0; i < pairs.length; i += 1) {
		const pair = pairs[i].split('=');
		ret[`${decodeURIComponent(pair[0])}`] = decodeURIComponent(pair[1]);
	}
	return ret;
};
