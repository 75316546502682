import React from 'react';
import Grid from '@exploreshare/everest/base/Grid';
import Link from '@exploreshare/everest/base/Link';
import Text from '@exploreshare/everest/base/Text';

const EnglishFaq = () => (
	<>
		<Grid item xs={12}>
			<Text bold size={14}>
				What happens if I need to cancel the trip because of the Coronavirus (COVID-19)?
			</Text>
			<Text size={14} color="grey">
				In case you need to cancel your Tour because of the Coronavirus (COVID-19), you will have one free change of
				dates: you will be able to put your trip on hold and postpone it for a later date.
				<br />
				<br />
				If you can’t postpone your trip, you will be offered a Voucher to book any tour at Explore-Share, valid for 18
				months from the original departure date of the Tour you originally booked. You will also have the chance to
				transfer this voucher to another person. This Voucher will be for the total price of the Tour you booked and
				paid, minus the eventual non-reimbursable expenses the Guide paid to organize the Tour.
				<br />
				<br />
				The above special cancellation policy will apply if:
				<ol type="a">
					<li>
						<Text size={14} color="grey">
							You notify the cancellation more than 30 days in advance, or{' '}
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							The authorities of the country of origin from where you are departing set an international travel ban that
							don’t allow you to leave your country, or{' '}
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							If the authorities of the country(ies) where your Tour will take place don’t allow you to enter it/them or
							set a mandatory quarantine that makes it impossible for you to do the Tour on the agreed date.
						</Text>
					</li>
				</ol>
				<br />
				Otherwise, the standard cancellation policy will apply. You can access it{' '}
				<Link to="https://www.explore-share.com/terms/" newTab>
					here
				</Link>{' '}
				(see “Services Provided by E&S” section).
				<br />
				<br />
				In some cases, under special circumstances, Explore-Share may be able to offer a refund instead. The refund will
				be in full, minus the non-refundable items mentioned in our Terms & Conditions (3% of the total price of the
				tour as an administration fee, 3% of the total price of the tour as service fee, the credit card fee if it
				applies, and the eventual non-reimbursable expenses the Guide paid to organize the Tour, which will be detailed
				by the guide)
				<br />
				<br />
				For more detailed information about our Covid-19 Exceptional Cancellation Policies, please visit the following
				page:{' '}
				<Link to="https://www.explore-share.com/coronavirus/" newTab>
					https://www.explore-share.com/coronavirus/
				</Link>
				.
			</Text>
		</Grid>
		<Grid item xs={12}>
			<Text bold size={14}>
				Will I be able to discuss my itinerary / suitability for the trip with my guide before paying?
			</Text>
			<Text size={14} color="grey">
				Yes. After you request to book a trip, you’ll have the chance to ask your guide any questions you may have
				before we charge your credit card.
			</Text>
		</Grid>
		<Grid item xs={12}>
			<Text bold size={14}>
				What happens if I need to cancel the trip?
			</Text>
			<Text bold size={14}>
				If you book your Tour more than 30 days in advance, you will have a 48-hour span after booking to cancel and
				receive a full refund without any deductions.
			</Text>
			<Text size={14} color="grey">
				After that period of time, if you decide to cancel, you’ll get a 100% refund (minus fees) if the cancellation is
				notified up to 60 days before the start of the tour, a 75% refund (minus fees) if you cancel between 59 and 30
				days before the start of the tour, and a 50% refund (minus fees) if you cancel between 29 and 21 days before the
				start of the tour. If you cancel less than 21 days before the first day of the tour, you won’t receive a refund.
				<br />
				<br />
				Non-refundable fees that will be discounted from the refund include:
				<ul>
					<li>
						<Text size={14} color="grey">
							Administration fee: 3% of the total price of the tour
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							Service fee: 3% of the total price of the tour
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							The eventual non-reimbursable expenses the Guide paid to organize the Tour (accommodation, administration
							expenses, salaries, porters, food, etc.) To be defined by the guide at the moment of the client's request
							for cancellation.
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							Credit Card fee: approximately 1,6% of the amount paid by credit card.
						</Text>
					</li>
					<li>
						<Text size={14} color="grey">
							Any other fee that had been clearly mentioned to the client as non-refundable before the client booked.
						</Text>
					</li>
				</ul>
				<br />
			</Text>
			<Text size={14} color="grey">
				If bad weather or unsuitable conditions force the guide to cancel the Tour, they will: a) propose a similar
				activity at a different place where conditions are better, or b) propose to postpone the activity until a later
				date, or c) cancel. If you refuse option a), the cancellation policy will apply. Please note, however, that the
				call to cancel a Tour due to bad weather can only be made by the guide (not the client). Under exceptional
				circumstances, the guide could decide at their sole discretion to refund the client (minus the transaction fee).
			</Text>
			<br />
			<Text size={14} color="grey">
				Take into account, however, that if accommodation, flights or transfers were included in the trip you booked,
				then a different policy may apply as the guide will be bound by the provider’s cancellation policy.
			</Text>
			<br />
			<Text size={14} color="grey">
				The 3% Explore-Share customer service fee paid at the moment of the booking is non-refundable. It is advisable
				to have an insurance policy that covers cancellations.
			</Text>
		</Grid>
		<Grid item xs={12}>
			<Text bold size={14}>
				How can I pay for my trip?
			</Text>
			<Text size={14} color="grey">
				You can use your credit card to pay for the trip. We use Stripe, a widely reputed payment processor, to
				guarantee that your payment will be secure.
			</Text>
		</Grid>
		<Grid item xs={12}>
			<Text bold size={14}>
				What equipment do I need to bring? Do you have a gear list?
			</Text>
			<Text size={14} color="grey">
				The Guide will be happy to help you get ready for your adventure. After your booking is confirmed, they will
				send you all the details and a gear list if necessary. They may also be able to help you with rentals if needed.
			</Text>
		</Grid>
		<Grid item xs={12}>
			<Text bold size={14}>
				Why should I book through Explore-Share?
			</Text>
			<Text size={14} color="grey">
				The widest offer in the world: With over 11,000 trips listings in 70+ countries, we are, hands down, the largest
				adventure-booking platform in the world. Our community of 1,700+ experienced guides will ensure the safest
				experience possible but also that you go home with a big smile on your face. It is a fact: adventuring makes
				people happy.
				<br />
				<br />
				Support local economy: by hiring a local guide you will not just be doing what you love and enjoying a memorable
				experience; you will also be supporting local economies and allowing great guides to make a living out of their
				passion. Unlike traditional travel agencies, close to 85% of the money you spend on Explore-Share will go to
				local guides and agencies.
				<br />
				<br />
				Your money is safe: We make it safe for you to send money to local guides on the other side of the globe. We
				collect and keep your money until 30-days before the trip starts, making sure all planning is going well. A
				secure process for you and for the Guide!
			</Text>
		</Grid>
	</>
);

export default EnglishFaq;
