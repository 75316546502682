import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import { injectStripe } from 'react-stripe-elements';
import 'react-credit-cards/es/styles-compiled.css';

import Modal from '@exploreshare/everest/base/Modal';
import Grid from '@exploreshare/everest/base/Grid';
import Container from '@exploreshare/everest/base/Container';
import Text from '@exploreshare/everest/base/Text';
import Button from '@exploreshare/everest/base/Button';
import Select from '@exploreshare/everest/base/Select';
import Input from '@exploreshare/everest/base/Input';
import Info from '@exploreshare/everest/icons/Info';
import Header from '@exploreshare/everest/base/Header';
import PhoneInput from '@exploreshare/everest/base/PhoneInput';
import CheckBox from '@exploreshare/everest/base/CheckBox';
import Link from '@exploreshare/everest/base/Link';

import styles from './Payment.module.css';
import DateItem from '../Commons/Items/DateItem';
import SimpleItem from '../Commons/Items/SimpleItem';

import visa from '../../assets/img/visa.svg';
import mastercard from '../../assets/img/mastercard.svg';
import amex from '../../assets/img/amex.svg';
import discover from '../../assets/img/discover.svg';
import unknown from '../../assets/img/unknownCard.svg';

import { CREDIT_CARD_FEE, ga, getQueryParams } from '../../utils';

import { useWindowDimensions } from '../WindowDimensionsProvider';

import countries, { frCountries } from './countries';

import { formatCreditCard, getCreditCardBrand } from '../../utils/cards';
import formatExpiry from '../../utils/formatExpiry';
import { actions, selectors } from '../../modules/booking';
import { actions as appActions } from '../../modules/app';
import translations from './translations';

const isNumeric = v => /^[0-9\s]*$/.test(v);
const isExpiry = v => /^[0-9]{0,2}\/?[0-9]{0,2}$/.test(v);

const getCcFee = (percent, finalPrice) => {
  return (finalPrice * (percent / 100) * (CREDIT_CARD_FEE / 100))
}

const validations = {
	number: v => isNumeric(v) && v.replace(/[^0-9]/g, '').length <= 19,
	cvv: v => isNumeric(v) && v.length <= 4,
	expiry: isExpiry,
};

const cardIcon = { amex, discover, mastercard, visa, unknown };

const onChangeHandler = (field, setData, data) => event => {
	if (validations[field] && !validations[field](event.target.value)) {
		return;
	}
	if (!data[field].value && event.target.value) {
		if (field === 'country') ga.billingCountryLoaded(event.target.value);
		if (field === 'number') ga.cardNumberLoaded();
		if (field === 'expiry') ga.validThruLoaded();
		if (field === 'cvv') ga.cvvLoaded();
	}
	setData({ ...data, [field]: { ...data[field], value: event.target.value } });
};
const onBlurHandler = (field, setData, data) => () => setData({ ...data, [field]: { ...data[field], validate: true } });
const onFocusHandler = (field, setData, data) => () =>
	setData({ ...data, [field]: { ...data[field], validate: false } });

const SCREEN_BREAKPOINT_WIDTH = 800;

const RenderTripDetails = ({ availability, peopleCount, currency, finalPriceWithFee, creditCardFee, locale }) => {
	const { width } = useWindowDimensions();
	if (width > SCREEN_BREAKPOINT_WIDTH) return null;
	return (
		<>
			<hr className={styles.separator} />
			<div className={styles.header}>
				<Header className={styles.subtitle} level={3}>
					Trip details
				</Header>
			</div>
			<div className={styles.details}>
				{availability && (
					<DateItem
						title={translations[locale].availability}
						dates={availability.map(({ startDate, endDate }) => ({
							startDate: moment(startDate),
							endDate: moment(endDate),
						}))}
						canEdit={false}
					/>
				)}
				<SimpleItem title={translations[locale].groupSize} description={peopleCount} />
				<SimpleItem
					title={translations[locale].price}
					description={`${currency} ${finalPriceWithFee + (creditCardFee || 0)}`}
				/>
			</div>
		</>
	);
};

RenderTripDetails.propTypes = {
	availability: PropTypes.array,
	peopleCount: PropTypes.number,
	currency: PropTypes.string,
	finalPriceWithFee: PropTypes.number,
	creditCardFee: PropTypes.number,
	locale: PropTypes.string,
};

class Payment extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			isErrorModalOpen: false,
			locale: 'en',
		};
	}

	isBackDisabled = () => {
		const { lock, distribution, method } = this.props;
		if (lock && distribution && !method) return true;
		return false;
	};

	componentDidMount = () => {
		const { data, params } = this.props;
		const newState = {};
		if (data.firstName.value && data.firstName.valid) {
			newState.firstNameDisabled = true;
		}
		if (data.lastName.value && data.lastName.valid) {
			newState.lastNameDisabled = true;
		}
		if (data.phone.value && data.phone.valid) {
			newState.phoneDisabled = true;
		}
		this.setState({ ...newState, locale: params.locale ? params.locale : 'en' });
	};

	componentDidUpdate = prevProps => {
		const { requiresAction: prevRequiresAction, paymentError: prevPaymentError } = prevProps;
		const { requiresAction, stripe, validateSCAValidation, paymentError } = this.props;

		// Check SCA validation
		if (
			requiresAction &&
			requiresAction.status === 'requires_action' &&
			requiresAction.client_secret &&
			(!prevRequiresAction || prevRequiresAction.status !== 'requires_action' || !prevRequiresAction.client_secret)
		) {
			const { client_secret: clientSecret } = requiresAction;
			stripe.handleCardAction(clientSecret).then(validateSCAValidation);
		}

		// Check payment error
		if (!prevPaymentError && paymentError) {
			this.openErrorModal();
		}
	};

	openErrorModal = () => this.setState({ isErrorModalOpen: true });

	closeErrorModal = () => {
		const { setPaymentError } = this.props;
		setPaymentError(false);
		this.setState({ isErrorModalOpen: false });
	};

	onPaymentSelectionGoBack = () => {
		const { method, distribution, setPaymentDistribution, setPaymentMethod, lock } = this.props;

		if (method && distribution) return setPaymentMethod(undefined);
		if (lock) return;
		if (distribution) return setPaymentDistribution(undefined);
	};

	renderErrorModal = () => {
		const { isErrorModalOpen, locale } = this.state;
		return (
			<Modal isOpen={isErrorModalOpen} onClose={this.closeErrorModal} title={translations[locale].paymentError}>
				<Container>
					<Grid container spacing={3} direction="column" alignItems="center">
						<Grid item xs={12}>
							<Info size="lg" color="red" />
						</Grid>
						<Grid item xs={12}>
							<Text capitalized size={18} bold style={{ marginTop: 12 }}>
								{translations[locale].errorTitle}
							</Text>
						</Grid>
						<Grid item xs={12}>
							<Text style={{ textAlign: 'center' }}>{translations[locale].errorDesc}</Text>
						</Grid>
						<Grid item xs={12}>
							<Button primary onClick={this.closeErrorModal}>
								{translations[locale].goBackToCheckout}
							</Button>
						</Grid>
					</Grid>
				</Container>
			</Modal>
		);
	};

	renderBillingInformation = () => {
		const { data, setData } = this.props;
		const { locale } = this.state;
		const _countries = {
			fr: frCountries,
			en: countries,
		};
		return (
			<>
				<div className={styles.header}>
					<Header className={styles.subtitle} level={3}>
						{translations[locale].billingInformation}
					</Header>
				</div>

				<Input
					label={translations[locale].address}
					placeholder="Wallaby way"
					value={data.address.value}
					handleChange={onChangeHandler('address', setData, data)}
					handleBlur={onBlurHandler('address', setData, data)}
					handleFocus={onFocusHandler('address', setData, data)}
					errorLabels={data.address.errors}
					isValid={data.address.valid}
					ref={data.address.ref}
				/>

				<Input
					label={translations[locale].addressNumber}
					placeholder="42"
					value={data.addressNumber.value}
					handleChange={onChangeHandler('addressNumber', setData, data)}
					handleBlur={onBlurHandler('addressNumber', setData, data)}
					handleFocus={onFocusHandler('addressNumber', setData, data)}
					errorLabels={data.addressNumber.errors}
					isValid={data.addressNumber.valid}
					ref={data.addressNumber.ref}
				/>

				<Input
					label={translations[locale].zipCode}
					placeholder="33180"
					value={data.zip.value}
					handleChange={onChangeHandler('zip', setData, data)}
					handleBlur={onBlurHandler('zip', setData, data)}
					handleFocus={onFocusHandler('zip', setData, data)}
					errorLabels={data.zip.errors}
					isValid={data.zip.valid}
					ref={data.zip.ref}
				/>

				<Input
					label={translations[locale].city}
					placeholder="Sidney"
					value={data.city.value}
					handleChange={onChangeHandler('city', setData, data)}
					handleBlur={onBlurHandler('city', setData, data)}
					handleFocus={onFocusHandler('city', setData, data)}
					errorLabels={data.city.errors}
					isValid={data.city.valid}
					ref={data.city.ref}
				/>

				<Select
					label={translations[locale].country}
					fullWidth
					options={_countries[locale].map(country => ({ value: country, label: country }))}
					value={data.country.value}
					handleChange={onChangeHandler('country', setData, data)}
					isValid={data.country.valid}
					errorLabels={data.country.errors}
					placeholder={translations[locale].selectACountry}
					ref={data.country.ref}
				/>
			</>
		);
	};

	renderCreditCardInformation = () => {
		const { data, setData, finalPriceWithFee, finalPrice, currency, distribution, method, creditCardFee } = this.props;
		const { locale } = this.state;
		const totalAmountToCharge = (() => {
			if (distribution === 'total-now') {
				return finalPriceWithFee + (method === 'cc' ? creditCardFee : 0); // 100% now
			}
			return (finalPrice * 0.25 + finalPriceWithFee - finalPrice) + getCcFee(25, finalPrice); // 25% now and 75% later
		})();
		const totalAmountToChargeLater = (() => {
			if (distribution === 'total-now') return 0; // 100% now
			return finalPrice * 0.75 + (method === 'cc' ? creditCardFee : 0); // 25% now and 75% later
		})();
		return (
			<>
				<div className={styles.header}>
					<Header className={styles.subtitle} level={3}>
						🔒 {translations[locale].secureCardInfo}{' '}
						{distribution === '75-later'
							? `(${currency} ${(finalPrice * 0.25 + finalPriceWithFee - finalPrice).toFixed(2)} now)`
							: ''}
					</Header>
				</div>
				<Input
					label={translations[locale].nameOnCard}
					placeholder="P. Sherman"
					value={data.name.value}
					handleChange={onChangeHandler('name', setData, data)}
					handleBlur={onBlurHandler('name', setData, data)}
					handleFocus={onFocusHandler('name', setData, data)}
					errorLabels={data.name.errors}
					isValid={data.name.valid}
					ref={data.name.ref}
					name="ccname"
				/>
				<div className={styles.cardNumberContainer}>
					<Input
						label={translations[locale].cardNumber}
						placeholder="1234 1234 1234 1234"
						value={formatCreditCard(data.number.value)}
						handleChange={onChangeHandler('number', setData, data)}
						handleBlur={onBlurHandler('number', setData, data)}
						handleFocus={onFocusHandler('number', setData, data)}
						errorLabels={data.number.errors}
						isValid={data.number.valid}
						ref={data.number.ref}
						name="cardnumber"
						type="tel"
						className={styles.cardNumber}
					/>
					<img className={styles.cardIcon} src={cardIcon[getCreditCardBrand(data.number.value)]} alt="brand" />
				</div>
				<div className={styles.cardData}>
					<Input
						label={translations[locale].expirationDate}
						placeholder="MM/YY"
						value={formatExpiry(data.expiry.value)}
						handleChange={onChangeHandler('expiry', setData, data)}
						handleBlur={onBlurHandler('expiry', setData, data)}
						handleFocus={onFocusHandler('expiry', setData, data)}
						errorLabels={data.expiry.errors}
						isValid={data.expiry.valid}
						ref={data.expiry.ref}
						name="cc-exp"
						type="tel"
					/>
					<Input
						label={translations[locale].securityCode}
						placeholder="•••"
						value={data.cvv.value}
						handleChange={onChangeHandler('cvv', setData, data)}
						handleBlur={onBlurHandler('cvv', setData, data)}
						handleFocus={onFocusHandler('cvv', setData, data)}
						errorLabels={data.cvv.errors}
						isValid={data.cvv.valid}
						ref={data.cvv.ref}
						name="cvc"
						type="tel"
					/>
				</div>
				<Container className={`${styles.bgBlack10} ${styles.transferDetails} ${styles.borderNone}`}>
					<Text className={`${styles.mb8} ${styles.instructions}`}>
						{translations[locale].youWillPreAuthorize}{' '}
						<Text inline bold className={`${styles.instructions}`}>
							{currency} {totalAmountToCharge.toFixed(2)}
						</Text>
						{distribution === 'total-now' && method === 'cc' && (
							<Text inline>
								{' '}
								({currency} {finalPriceWithFee.toFixed(2)} + {currency} {creditCardFee.toFixed(2)} in credit card fees).
							</Text>
						)}{' '}
						{translations[locale].wontBeChargedYet}
						{distribution === '75-later' && method !== 'wire' && (
							<>
								<br />
								{translations[locale].asPerToRemainingBalance}{' '}
								<Text inline bold className={`${styles.instructions}`}>
									({currency} {totalAmountToChargeLater.toFixed(2)})
								</Text>{' '}
								{method === 'cc' ? translations[locale].automaticallyCharged : translations[locale].haveToTransfer}
							</>
						)}
					</Text>
				</Container>
			</>
		);
	};

	renderPersonalInformation = () => {
		const { data, setData } = this.props;
		const { phoneDisabled, firstNameDisabled, lastNameDisabled, locale } = this.state;
		return (
			<>
				<div className={styles.header}>
					<Header className={styles.subtitle} level={3}>
						{translations[locale].personalInformation}
					</Header>
				</div>
				<Grid container>
					<Grid item xs={6} className={styles.paddingRight}>
						{firstNameDisabled ? (
							<SimpleItem title={translations[locale].firstName} description={data.firstName.value} />
						) : (
							<Input
								label={translations[locale].firstName}
								placeholder="Indiana"
								value={data.firstName.value}
								handleChange={onChangeHandler('firstName', setData, data)}
								handleBlur={onBlurHandler('firstName', setData, data)}
								handleFocus={onFocusHandler('firstName', setData, data)}
								errorLabels={data.firstName.errors}
								isValid={data.firstName.valid}
								ref={data.firstName.ref}
							/>
						)}
					</Grid>
					<Grid item xs={6} className={styles.paddingLeft}>
						{lastNameDisabled ? (
							<SimpleItem title={translations[locale].lastName} description={data.lastName.value} />
						) : (
							<Input
								label={translations[locale].lastName}
								placeholder="Jones"
								value={data.lastName.value}
								handleChange={onChangeHandler('lastName', setData, data)}
								handleBlur={onBlurHandler('lastName', setData, data)}
								handleFocus={onFocusHandler('lastName', setData, data)}
								errorLabels={data.lastName.errors}
								isValid={data.lastName.valid}
								ref={data.lastName.ref}
							/>
						)}
					</Grid>
					<Grid item xs={12}>
						{phoneDisabled ? (
							<SimpleItem title={translations[locale].phone} description={data.phone.value} />
						) : (
							<PhoneInput
								label={translations[locale].phoneNumber}
								value={data.phone.value}
								handleChange={({ number }) => onChangeHandler('phone', setData, data)({ target: { value: number } })}
								handleBlur={onBlurHandler('phone', setData, data)}
								handleFocus={onFocusHandler('phone', setData, data)}
								errorLabels={data.phone.errors}
								isValid={data.phone.valid}
								ref={data.phone.ref}
							/>
						)}
					</Grid>
				</Grid>
			</>
		);
	};

	renderWireTransferInstructions = () => {
		const { distribution, code, currency, finalPrice, method, creditCardFee } = this.props;
		const { locale } = this.state;
		const totalAmountToChargeLater = (() => {
			if (distribution === 'total-now') return 0; // 100% now
			return finalPrice * 0.75 + (method === 'cc' ? creditCardFee : 0); // 25% now and 75% later
		})();
		return (
			<>
				<div className={styles.header}>
					<Header className={styles.subtitle} level={3}>
						{translations[locale].instructionsForBankTransfer}{' '}
						{distribution === '75-later' ? `(${currency} ${(finalPrice * 0.75).toFixed(2)} later)` : ''}
					</Header>
				</div>
				<Container className={styles.transferDetails}>
					{currency === 'USD' ? (
						<table className={styles.transferDetailsTable}>
							<tbody>
								<tr>
									<td valign="top">{translations[locale].payeeName}</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>Explore and Share</td>
								</tr>
								<tr>
									<td valign="top">{translations[locale].reference}</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>{code}</td>
								</tr>
								<br />
								<tr>
									<td className={`${styles.bold} ${styles.black90}`}>{translations[locale].localUsTransfer}</td>
								</tr>
								<tr>
									<td valign="top">{translations[locale].ach}</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>084009519</td>
								</tr>
								<tr>
									<td valign="top">{translations[locale].accountNumber}</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>9600000000029804</td>
								</tr>
								<tr>
									<td valign="top">Type</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>
										{translations[locale].checkings}
									</td>
								</tr>
								<br />
								<tr>
									<td className={`${styles.bold} ${styles.black90}`}>{translations[locale].international}</td>
								</tr>
								<tr>
									<td valign="top">SWIFT</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>CMFGUS33</td>
								</tr>
								<tr>
									<td valign="top">{translations[locale].wireNumber}</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>026073008</td>
								</tr>
								<tr>
									<td valign="top">{translations[locale].accountNumber}</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>8310003049</td>
								</tr>
								<br />
								<tr>
									<td valign="top">{translations[locale].bankAddress}</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>
										TransferWise
										<br />
										19 W 24th Street
										<br />
										New York 10010
										<br />
										{translations[locale].US}
									</td>
								</tr>
							</tbody>
						</table>
					) : (
						<table className={styles.transferDetailsTable}>
							<tbody>
								<tr>
									<td valign="top">{translations[locale].payeeName}</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>Explore and Share</td>
								</tr>
								<tr>
									<td valign="top">{translations[locale].reference}</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>{code}</td>
								</tr>
								<tr>
									<td valign="top">IBAN</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>BE16 0018 1423 7274</td>
								</tr>
								<tr>
									<td valign="top">{translations[locale].bankCode} (BIC/SWIFT)</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>GEBABEBBXXX</td>
								</tr>
								<tr>
									<td valign="top">{translations[locale].bankAddress}</td>
									<td className={`${styles.textRight} ${styles.bold} ${styles.black90}`}>
										BNP Paribas Fortis Etterbeek
										<br />
										Boulevard Louis Schmidt 119
										<br />
										1040 Etterbeek
										<br />
										{translations[locale].belgium}
									</td>
								</tr>
							</tbody>
						</table>
					)}
				</Container>
				<Text className={`${styles.black90} ${styles.instructions}`}>
					{distribution === '75-later'
						? translations[locale].pay75daysLater(currency, totalAmountToChargeLater)
						: translations[locale].wireNotice}
				</Text>
			</>
		);
	};

	renderHeader = () => {
		const { distribution, method, lock } = this.props;
		const { locale } = this.state;
		if (!distribution) {
			return (
				<>
					<div className={styles.title}>
						<Header level={2}>🔒 {translations[locale].securePaymentInfo}</Header>
					</div>
					<div className={styles.title}>
						<Header className={styles.subtitle} level={3}>
							{translations[locale].howToPay}
						</Header>
					</div>
				</>
			);
		}

		const backButtonLabel = (() => {
			if (lock && !method) return '';
			if (method) return `< ${translations[locale].changePaymentMethod}`;
			return `< ${translations[locale].changePayment}`;
		})();

		return (
			<>
				<div className={styles.title}>
					<Header level={2}>
						{distribution && method ? translations[locale].paymentInfo : translations[locale].confirmAndPay}
					</Header>
				</div>
				{distribution && (
					<div className={styles.title}>
						<Button type="text" disabled={this.isBackDisabled()} onClick={this.onPaymentSelectionGoBack}>
							{backButtonLabel}
						</Button>
					</div>
				)}
			</>
		);
	};

	selectPaymentDistribution = distribution => () => {
		const { setPaymentDistribution } = this.props;
		setPaymentDistribution(distribution);
	};

	selectPaymentMethod = method => () => {
		const { setPaymentMethod } = this.props;
		setPaymentMethod(method);
	};

	renderBody = () => {
		const {
			customerFee,
			distribution,
			method,
			currency,
			finalPrice,
			finalPriceWithFee,
			availability,
			peopleCount,
			creditCardFee,
			lock,
			data,
			setData,
		} = this.props;
		const { locale } = this.state;
		if (!distribution) {
			return (
				<>
					<Container className={styles.card} onClick={this.selectPaymentDistribution('total-now')}>
						<Text bold>{translations[locale].payInFull}</Text>
					</Container>
					<Container className={styles.card} onClick={this.selectPaymentDistribution('75-later')}>
						<Text bold>{translations[locale].payLessUpfront}</Text>
						<Text className={styles.black90}>
							{currency} {((finalPrice * 0.25 + finalPriceWithFee - finalPrice) + getCcFee(25, finalPrice)).toFixed(2)}{' '}
							{translations[locale].ccNowRestLater}
						</Text>
					</Container>
				</>
			);
		}
		if (!method) {
			return (
				<>
					<Text bold>{lock ? translations[locale].howPaymentWillOccur : translations[locale].youHaveChosen}</Text>
					<Container className={`${styles.card} ${styles.selected}`}>
						{distribution === 'total-now' ? (
							<Text>• {translations[locale].payInFull}</Text>
						) : (
							<>
								<Text>• {translations[locale].payLessUpfront}</Text>
								<Text className={styles.black90}>
									&nbsp;&nbsp;{currency} {((finalPrice * 0.25 + finalPriceWithFee - finalPrice) + getCcFee(25, finalPrice)).toFixed(2)}{' '}
									{translations[locale].ccNowRestLater}
								</Text>
							</>
						)}
					</Container>
					<hr className={styles.separator} />
					<div className={styles.title}>
						<Header className={styles.subtitle} level={3}>
							{distribution === 'total-now'
								? translations[locale].choosePaymentMethod
								: translations[locale].choosePaymentMethodForRemaining}
						</Header>
					</div>
					{distribution === '75-later' && (
						<>
							<Text className={`${styles.black90} ${styles.mb8} ${styles.instructions}`}>
								{translations[locale].forRemainingAmount}{' '}
								<Text inline bold className={`${styles.black90} ${styles.instructions}`}>
									({currency} {finalPrice * 0.75})
								</Text>{' '}
								{translations[locale].chooseWheterToPay}
							</Text>
						</>
					)}
					<Container className={styles.card} onClick={this.selectPaymentMethod('cc')}>
						<Text inline bold>
							💳 {translations[locale].creditCard}
						</Text>
						&nbsp;
						<Text inline className={styles.black90}>
							(+{currency}{' '}
              {(finalPrice * (distribution === 'total-now' ? 1 : 0.75) * (CREDIT_CARD_FEE / 100)).toFixed(2)})
						</Text>
						{distribution === '75-later' ? (
							<Text className={styles.black90}>
								<Text inline bold className={styles.black90}>
									{currency} {(finalPrice * 0.75 + getCcFee(75, finalPrice)).toFixed(2)}
								</Text>{' '}
								{translations[locale].charged45DaysBeforeDP}
							</Text>
						) : (
							<Text className={styles.black90}>
								{translations[locale].youWillPayATotal}{' '}
								<Text inline bold className={styles.black90}>
									{currency} {(finalPriceWithFee + (finalPrice * CREDIT_CARD_FEE) / 100).toFixed(2)}
								</Text>
							</Text>
						)}
					</Container>
					<Container className={styles.card} onClick={this.selectPaymentMethod('wire')}>
						<Text inline bold>
							🏦 {translations[locale].bankTransfer}
						</Text>
						&nbsp;
						<Text inline className={styles.black90}>
							(+{currency} {(0).toFixed(2)})
						</Text>
						{distribution === '75-later' ? (
							<Text className={styles.black90}>{translations[locale].weWillProvideInstructions}</Text>
						) : (
							<Text className={styles.black90}>
								{translations[locale].youWillPayATotal}{' '}
								<Text inline bold className={styles.black90}>
									{currency} {finalPriceWithFee.toFixed(2)}
								</Text>
							</Text>
						)}
					</Container>
				</>
			);
		}
    const amountNowWithCard = finalPrice * 0.25 + finalPriceWithFee - finalPrice;
		return (
			<>
				<Text bold>{lock ? 'How the payment will occur:' : 'You have chosen:'}</Text>
				<Container className={`${styles.card} ${styles.selected}`}>
					{distribution === 'total-now' ? (
						<Text>• {translations[locale].payInFull}</Text>
					) : (
						<>
							<Text>• {translations[locale].payLessUpfront}</Text>
							<Text className={styles.black90}>
								&nbsp;&nbsp;{currency} {(amountNowWithCard + getCcFee(25, finalPrice)).toFixed(2)}{' '}
								{translations[locale].ccNowRestLater}
							</Text>
						</>
					)}
				</Container>
				<Container className={`${styles.card} ${styles.selected}`}>
					{method === 'cc' ? (
						<>
							<Text inline>• 💳 {translations[locale].creditCard}</Text>
							&nbsp;
							<Text inline className={styles.black90}>
								(+{currency}{' '}
								{(finalPrice * (distribution === '75-later' ? 0.75 : 1) * (CREDIT_CARD_FEE / 100)).toFixed(2)})
							</Text>
						</>
					) : (
						<>
							<Text inline>• 🏦 {translations[locale].bankTransfer}</Text>
							&nbsp;
							<Text inline className={styles.black90}>
								(+{currency} {(0).toFixed(2)})
							</Text>
						</>
					)}
				</Container>
				<RenderTripDetails
					availability={availability}
					peopleCount={peopleCount}
					currency={currency}
					finalPriceWithFee={finalPriceWithFee}
					creditCardFee={creditCardFee}
					locale={locale}
				/>
				<hr className={styles.separator} />
				{this.renderPersonalInformation()}
				{(method === 'cc' || distribution === '75-later') && (
					<>
						<hr className={styles.separator} />
						{this.renderCreditCardInformation()}
					</>
				)}
				{parseInt(customerFee) ? (
					<>
						<hr className={styles.separator} />
						{this.renderBillingInformation()}
					</>
				) : null}
				{method === 'wire' ? this.renderWireTransferInstructions() : null}
				<Grid container direction="row" alignItems="center">
					<Grid item>
						<CheckBox
							selected={data.acceptedTerms.value}
							onClick={selected => onChangeHandler('acceptedTerms', setData, data)({ target: { value: selected } })}
						/>
					</Grid>
					<Grid item>
						<Text inline>{translations[locale].readAndAccept} </Text>
						<Link newTab to="https://www.explore-share.com/terms/">
							{translations[locale].termsAndConditions}
						</Link>
					</Grid>
				</Grid>
				{!data.acceptedTerms.valid && data.acceptedTerms.errors[0] && (
					<Text color="red" size={10}>
						{data.acceptedTerms.errors[0]}
					</Text>
				)}
			</>
		);
	};

	render = () => (
		<div className={styles.container}>
			{this.renderErrorModal()}
			{this.renderHeader()}
			{this.renderBody()}
		</div>
	);
}

Payment.propTypes = {
	data: PropTypes.object,
	stripe: PropTypes.object,
	requiresAction: PropTypes.object,
	setData: PropTypes.func.isRequired,
	availability: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			endDate: PropTypes.string.isRequired,
			startDate: PropTypes.string.isRequired,
		})
	),
	peopleCount: PropTypes.number.isRequired,
	finalPriceWithFee: PropTypes.number.isRequired,
	finalPrice: PropTypes.number.isRequired,
	currency: PropTypes.string.isRequired,
};

const mapStateToProps = ({ bookingReducer, app }) => ({
	requiresAction: bookingReducer.requiresAction,
	paymentError: bookingReducer.paymentError,
	customerFee: bookingReducer.booking.customer_fee,
	code: bookingReducer.booking.code,
	distribution: app.distribution,
	method: app.method,
	lock: app.lock,
	creditCardFee: selectors.getCreditCardFee(bookingReducer, app),
	params: getQueryParams(),
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			validateSCAValidation: actions.validateSCAValidation,
			setPaymentError: actions.setPaymentError,
			setPaymentDistribution: appActions.setPaymentDistribution,
			setPaymentMethod: appActions.setPaymentMethod,
		},
		dispatch
	);

Payment.propTypes = {
	customerFee: PropTypes.number,
	validateSCAValidation: PropTypes.func,
	setPaymentError: PropTypes.func,
	paymentError: PropTypes.bool,
	stripe: PropTypes.object,
	setPaymentDistribution: PropTypes.func,
	setPaymentMethod: PropTypes.func,
	distribution: PropTypes.string,
	method: PropTypes.string,
	code: PropTypes.string,
	creditCardFee: PropTypes.number,
	lock: PropTypes.bool,
	params: PropTypes.shape({
		locale: PropTypes.string,
	}),
};

const connectedPayment = connect(mapStateToProps, mapDispatchToProps)(Payment);
export default injectStripe(connectedPayment);
