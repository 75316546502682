import React from 'react';
import PropTypes from 'prop-types';
import styles from './Checkout.module.css';
import ButtonActions from '../Commons/ButtonActions';
import Steps from '../Commons/Steps';
import Wizard from '../Wizard';
import { useWindowDimensions } from '../WindowDimensionsProvider';
import TripSummary from '../TripSummary';

const SCREEN_BREAKPOINT_WIDTH = 800;

const Checkout = props => {
	const {
		step,
		labels,
		children,
		actions,
		duration,
		peopleCount,
		title,
		pricePerPerson,
		currency,
		finalPrice,
		finalPriceWithFee,
		whatsIncluded,
		daysCount,
		toggleFaq,
		coverURL,
		availability,
		openGroup,
	} = props;

	const { width } = useWindowDimensions();
	return (
		<div className={styles.container}>
			{width > SCREEN_BREAKPOINT_WIDTH ? (
				<div className={styles.desktopSteps}>
					<Steps step={step} labels={labels} />
				</div>
			) : null}
			<div className={styles.content}>
				<div className={styles.form}>
					{width < SCREEN_BREAKPOINT_WIDTH ? <Steps step={step} labels={labels} /> : null}
					<Wizard currentStep={step}>{children}</Wizard>
				</div>
				{width > SCREEN_BREAKPOINT_WIDTH ? (
					<div>
						<div className={styles.summary}>
							<TripSummary
								duration={duration}
								peopleCount={peopleCount}
								title={title}
								coverURL={coverURL}
								pricePerPerson={pricePerPerson}
								currency={currency}
								finalPrice={finalPrice}
								finalPriceWithFee={finalPriceWithFee}
								whatsIncluded={whatsIncluded}
								daysCount={daysCount}
								availability={availability}
								openGroup={openGroup}
							/>
						</div>
					</div>
				) : null}
			</div>
			<ButtonActions className={styles.actions} {...actions} availability={availability} faqAction={toggleFaq} />
		</div>
	);
};

Checkout.propTypes = {
	step: PropTypes.number,
	labels: PropTypes.array,
	children: PropTypes.node,
	actions: PropTypes.object,
	duration: PropTypes.number.isRequired,
	peopleCount: PropTypes.number.isRequired,
	title: PropTypes.string.isRequired,
	coverURL: PropTypes.string,
	openGroup: PropTypes.string,
	pricePerPerson: PropTypes.number,
	currency: PropTypes.string.isRequired,
	finalPrice: PropTypes.number.isRequired,
	finalPriceWithFee: PropTypes.number.isRequired,
	whatsIncluded: PropTypes.arrayOf(PropTypes.string),
	daysCount: PropTypes.string,
	toggleFaq: PropTypes.func,
	availability: PropTypes.array,
};

export default Checkout;
