import React from 'react';
import PropTypes from 'prop-types';

import Text from '@exploreshare/everest/base/Text';
import Image from '@exploreshare/everest/base/Image';
import styles from './Header.module.css';

const Header = ({ title, description, coverURL }) => (
	<div className={styles.container}>
		<div className={styles.data}>
			<div className={styles.title}>
				<Text bold size="14px">
					{title}
				</Text>
			</div>
			<div className={styles.description}>
				<Text size="14px" color="grey">
					{description}
				</Text>
			</div>
		</div>
		{coverURL && (
			<div className={styles.img}>
				<Image src={coverURL} alt={title} rounded />
			</div>
		)}
	</div>
);

Header.propTypes = {
	title: PropTypes.string,
	description: PropTypes.string,
	coverURL: PropTypes.string,
};

export default Header;
