import update from 'immutability-helper';
import * as types from './types';
import initialState from '../../initialState';

export default (state = initialState.bookingReducer, { type, payload }) => {
	switch (type) {
		case types.GET_BOOKING_START:
			return update(state, {
				isFetching: { $set: payload.isFetching },
			});

		case types.POST_DATA_SUCCESS:
			return update(state, {
				success: { $set: payload.success },
			});
		case types.PAYMENT_REQUIRES_ACTION:
			return update(state, {
				requiresAction: { $set: payload.requiresAction },
			});
		case types.POST_TRAVELLERS_SUCCESS:
			return update(state, {
				postTravellersSuccess: { $set: payload.postTravellersSuccess },
			});

		case types.SET_SUBMITTING:
			return update(state, {
				isSubmitting: { $set: payload.isSubmitting },
			});

		case types.GET_BOOKING_SUCCESS:
			return update(state, {
				booking: { $set: payload.booking },
			});

		case types.GET_BOOKING_FAIL:
			return update(state, {
				error: { $set: payload.error },
			});

		case types.SET_PAYMENT_ERROR:
			return update(state, {
				paymentError: { $set: payload.paymentError },
			});

		case types.SET_BOOKING_ERROR:
			return update(state, {
				error: { $set: payload.error },
			});

		case types.GET_BOOKING_END:
			return update(state, {
				isFetching: { $set: payload.isFetching },
			});

		case types.SET_TRAVELLERS:
			return update(state, {
				travellers: { $set: payload.travellers },
			});

		case types.SET_LEAD:
			return update(state, {
				lead: { $set: payload.lead },
				payment: {
					firstName: { $set: payload.lead.firstName },
					lastName: { $set: payload.lead.lastName },
					phone: { $set: payload.lead.phone },
				},
			});
		case types.SET_PAYMENT:
			return update(state, {
				payment: { $set: payload.payment },
			});

		default:
			return state;
	}
};
