import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';
import injectSheet from 'react-jss';
import Button from '@exploreshare/everest/base/Button';
import Text from '@exploreshare/everest/base/Text';
import styles from './buttonActions.styles';
import useI18n from '../../../i18n/usei18n';

const ButtonActions = ({ primary, secondary, style, classes, className, faqAction, availability }) => {
	const { t } = useI18n();
	const firstAvailabilityDate = availability.reduce(
		(min, range) => Math.min(min, Number(moment(range.startDate))),
		Infinity
	);
	const freeCancelIn48Hours = moment(firstAvailabilityDate).isAfter(moment().add(30, 'days'));
	return (
		<div className={classNames(classes.actions, className)} style={style}>
			<div className={classes.buttons}>
				{secondary ? (
					<div className={classes.decline}>
						<Button secondary fullWidth onClick={secondary.clickHandler} disabled={secondary.disabled}>
							{secondary.label}
						</Button>
					</div>
				) : null}
				<div className={classNames(classes.accept, !secondary && classes.onlyPrimary)}>
					<Button primary fullWidth onClick={primary.clickHandler} disabled={primary.disabled}>
						{t(primary.label)}
					</Button>
				</div>
				{freeCancelIn48Hours ? (
					<div className={classes.freeCancellation}>
						<Text size={12} color="grey">
							{t('freeCancellation')}
						</Text>
					</div>
				) : null}
				<div className={classes.faq}>
					<Text bold size={14} onClick={faqAction}>
						{t('supportAndFaqs')}
					</Text>
				</div>
			</div>
		</div>
	);
};

ButtonActions.propTypes = {
	primary: PropTypes.object,
	secondary: PropTypes.object,
	style: PropTypes.object,
	classes: PropTypes.object,
	className: PropTypes.string,
	faqAction: PropTypes.func,
	availability: PropTypes.array,
};

export default injectSheet(styles)(ButtonActions);
