import React from 'react';
import PropTypes from 'prop-types';

export const Wizard = props => {
	const { children, currentStep, goToStep } = props;
	return (
		<div id="myWizard" className="container">
			{React.Children.map(children, child =>
				child.props.stepKey === currentStep ? React.cloneElement(child, { goToStep }) : null
			)}
		</div>
	);
};

Wizard.propTypes = {
	children: PropTypes.node.isRequired,
	currentStep: PropTypes.number.isRequired,
	goToStep: PropTypes.func,
};
