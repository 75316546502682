import React from 'react';
import PropTypes from 'prop-types';
import injectSheet from 'react-jss';
import Image from '@exploreshare/everest/base/Image';
import Text from '@exploreshare/everest/base/Text';
import Grid from '@exploreshare/everest/base/Grid';
import Button from '@exploreshare/everest/base/Button';
import infoRed from '../../../assets/img/infoRed.svg';

const styles = () => ({
	root: {
		padding: 20,
	},
	alignMiddle: {
		display: 'flex',
		justifyContent: 'center',
	},
	successImage: {
		width: 77,
		height: 77,
	},
	button: {
		width: 273,
	},
});
const Error = props => {
	const { classes, goToStep, goToStatusPage } = props;
	return (
		<Grid container spacing={2} className={classes.root} justify="center">
			<Grid item className={classes.alignMiddle} xs={12}>
				<Image src={infoRed} alt="Success message Icon" className={classes.successImage} />
			</Grid>
			<Grid item className={classes.alignMiddle} xs={12}>
				<Text size={20} bold>
					Something went wrong
				</Text>
			</Grid>
			<Grid item className={classes.alignMiddle} xs={12}>
				<Text size={14} style={{ textAlign: 'center' }}>
					Please try again or do it later
				</Text>
			</Grid>
			<Grid item className={classes.alignMiddle} xs={12}>
				<Button className={classes.button} primary onClick={() => goToStep(2)}>
					Try again!
				</Button>
			</Grid>
			<Grid item className={classes.alignMiddle} xs={12}>
				<Button className={classes.button} secondary onClick={goToStatusPage}>
					I will do it later
				</Button>
			</Grid>
		</Grid>
	);
};
Error.propTypes = {
	classes: PropTypes.object,
	goToStep: PropTypes.func.isRequired,
	goToStatusPage: PropTypes.func,
};

export default injectSheet(styles)(Error);
