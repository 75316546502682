import React, { useState } from 'react';
import PropTypes from 'prop-types';

import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import './react-dates.css';

import moment from 'moment';

import Text from '@exploreshare/everest/base/Text';
import Button from '@exploreshare/everest/base/Button';
import styles from './Modal.module.css';
import PlainModal from '../../../Modal/PlainModal';
import InfoModal from '../../../InfoModal';
import Heading from '../../../Heading';
import Icon from '../../../Icon';

const Modal = ({ isOpen, closeButtonHandler, title, onChange, propStartDate, propEndDate }) => {
	const [{ startDate, endDate }, setDates] = useState({
		startDate: propStartDate,
		endDate: propEndDate,
	});
	const [focusedInput, setFocusInput] = useState('startDate');
	// setDates({ startDate: propStartDate, endDate: propEndDate });
	return (
		<PlainModal isOpen={isOpen} closeButtonHandler={closeButtonHandler} title={title}>
			<>
				<div className={styles.container}>
					<Heading size={4}>Choosing availability</Heading>
					<div>
						<Text>
							Please, select which days you would be able to take the trip. The guide will set the departure and arrival
							date based on your availability and trip duration.
						</Text>
					</div>
					<div className={styles.durationContainer}>
						<span className={styles.tripDuration}>
							<Text bold>Trip duration: 3 days</Text>
						</span>
					</div>

					<div className={styles.daypicker}>
						<DayPickerRangeController
							numberOfMonths={1}
							startDate={startDate} // momentPropTypes.momentObj or null,
							endDate={endDate} // momentPropTypes.momentObj or null,
							onDatesChange={({ start, end }) => {
								setDates({ startDate: start, endDate: end });
							}}
							focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
							onFocusChange={input => setFocusInput(input || 'startDate')}
							initialVisibleMonth={() => moment().add(1, 'M')}
						/>
					</div>
				</div>
				<InfoModal title="Selected dates:">
					<div>
						<Text color="white">These are the dates you are available to do the trip</Text>
					</div>
					<div className={styles.selectedDates}>
						{startDate && endDate ? (
							<>
								<Text bold color="white">
									{startDate.format('ll')}
								</Text>
								<Icon name="continue" />
								<Text bold color="white">
									{endDate ? endDate.format('ll') : null}
								</Text>
							</>
						) : (
							<Text color="white">No dates selected</Text>
						)}
					</div>
					<Button
						size={1}
						onClick={() => {
							if (onChange) onChange(startDate, endDate);
							closeButtonHandler();
						}}>
						Confirm dates
					</Button>
				</InfoModal>
			</>
		</PlainModal>
	);
};

Modal.propTypes = {
	isOpen: PropTypes.bool,
	closeButtonHandler: PropTypes.func,
	title: PropTypes.string,
	onChange: PropTypes.func,
	propStartDate: PropTypes.any,
	propEndDate: PropTypes.any,
};

export default Modal;
