import React from 'react';
// import TagManager from 'react-gtm-module';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';
import NavBar from '@exploreshare/everest/base/NavBar';
import Image from '@exploreshare/everest/base/Image';
import { actions, selectors } from '../../modules/booking';
import { actions as appActions } from '../../modules/app';
import BookingDetails from '../../components/BookingDetails';
import Payment from '../../components/Payment';
import { CheckoutComponent } from '../../components/Checkout';
import {
	validatePayment,
	validateTravellerInfo,
	formatPhone,
	validateContactInfo,
	validatePhone,
	validateBillingInfo,
} from '../../utils/validations';
import { getQueryParams, base64DecodeUrl, cleanObj, getIn, ga } from '../../utils';
import { getTrip, createBooking } from '../../utils/api';
import Loading from '../../components/Commons/Loading';
import Faq from '../../components/Faq';
import WidgetModal from '../../components/WidgetModal';
import { env, la2Url } from '../../config';
import styles from './styles.module.css';

const steps = {
	fr: {
		bookingDetails: 'Détails de la réservation',
		confirmAndPay: 'Confirmation et paiement',
	},
	en: {
		bookingDetails: 'Booking details',
		confirmAndPay: 'Confirm and pay',
	},
};

class Checkout extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentStep: 1,
			labels: {
				1: steps[props.params.locale || 'en'].bookingDetails,
				// 2: 'Travellers’ info',
				3: steps[props.params.locale || 'en'].confirmAndPay,
			},
			faqView: false,
			isModalOpen: false,
			contactInfo: {
				firstName: {
					value: '',
					valid: false,
					errors: [],
					ref: React.createRef(),
					required: true,
				},
				lastName: {
					value: '',
					valid: false,
					errors: [],
					ref: React.createRef(),
					required: false,
				},
				email: {
					value: '',
					valid: false,
					errors: [],
					ref: React.createRef(),
					required: true,
				},
				phone: {
					value: '',
					valid: false,
					errors: [],
					ref: React.createRef(),
					required: false,
				},
			},
			tripPageUrl: '',
			contactInfoError: false,
			isModalSubmitting: false,
			bookingId: '',
		};
	}

	componentDidMount() {
		ga.pageView();
		const { params } = this.props;
		const bookingId = params.bookingId || sessionStorage.getItem('exploreshare:bookingId');
		const token = params.token || sessionStorage.getItem('exploreshare:token');

		if (params.data && !bookingId && !token) {
			this.startModal();
		} else {
			this.startCheckout();
		}
	}

	componentDidCatch(error, errorInfo) {
		if (['production', 'staging'].includes(env)) {
			Sentry.withScope(scope => {
				Object.keys(errorInfo).forEach(key => {
					scope.setExtra(key, errorInfo[key]);
				});
				Sentry.captureException(error);
			});
		}
	}

	startModal = async () => {
		const { params, getBookingSuccess, setBookingError } = this.props;
		try {
			const queryData = JSON.parse(base64DecodeUrl(params.data));
			const trip = await getTrip(queryData.trip);
			// fill booking with props we have until now to get them as props
			await getBookingSuccess({
				duration: queryData.duration || trip.data.duration[0],
				availability: queryData.availability.map(el => ({
					_id: String(el[0] + el[1]),
					startDate: el[0],
					endDate: el[1],
				})),
				people_count: queryData.people_count,
				price_per_person: trip.data.pricing[queryData.people_count],
				trip: trip.data,
			});
			this.setState({
				isModalOpen: true,
				tripPageUrl: trip.data.link,
				queryData,
			});
		} catch (err) {
			Sentry.captureException(err);
			setBookingError();
		}
	};

	validateInput = input => input.ref && input.ref.current && input.ref.current.validate && input.ref.current.validate();

	showErrors = inputGroup => Object.values(inputGroup).forEach(this.validateInput);

	submitModal = async () => {
		const { contactInfo, queryData } = this.state;
		validateContactInfo(contactInfo);
		if (!contactInfo.valid) {
			this.setState(contactInfo);
			this.showErrors(contactInfo);
			return;
		}
		const { firstName, lastName, phone, email } = contactInfo;
		// set submitting to block btn
		this.setState({ isModalSubmitting: true });
		try {
			// get all values
			const params = cleanObj({
				email: email.value,
				persons: queryData.people_count,
				availability: queryData.availability.map(el => ({
					startDate: el[0],
					endDate: el[1],
				})),
				trip: queryData.trip,
				first_name: firstName.value,
				last_name: lastName.value,
				phone: formatPhone(phone.value) ? phone.value : undefined,
				duration: getIn(queryData, ['duration'], undefined),
				customer_fee: queryData.customer_fee || 0,
				google_click_id: getIn(queryData, ['google_click_id'], undefined),
				google_session_id: getIn(queryData, ['google_session_id'], undefined),
				google_user_id: getIn(queryData, ['google_user_id'], undefined),
			});
			// post api to create booking
			const booking = await createBooking(params);

			ga.onRequest(queryData.trip);
			// get params && redirect to the same url with new query params
			const bookingId = booking.data.booking._id;
			const { token } = booking.data;
			if (booking.data.pending) {
				window.location = `${la2Url}/status/${bookingId}?token=${token}`;
				return;
			}
			if (bookingId && token && window) {
				window.history.pushState({}, '', `/?bookingId=${bookingId}&token=${token}`);
				window.location.reload();
			} else {
				throw new Error('bookingId and token are required');
			}
		} catch (err) {
			Sentry.captureException(err);
			this.setState({ contactInfoError: true });
		} finally {
			this.setState({ isModalSubmitting: false });
		}
	};

	backToWidgetModal = () => {
		this.setState({ contactInfoError: false });
	};

	changeInputValue = field => e => {
		const { value } = e.target;
		const { state } = this;
		if (!state.contactInfo[field].value && value) {
			if (field === 'firstName') ga.firstNameLoaded();
			if (field === 'lastName') ga.lastNameLoaded();
			if (field === 'email') ga.emailLoaded();
			if (field === 'phone') ga.phoneLoaded();
		}

		this.setState(prev => {
			const contactInfo = {
				...prev.contactInfo,
				[field]: { ...prev.contactInfo[field], value },
			};
			validateContactInfo(contactInfo);
			return { contactInfo };
		});
	};

	startCheckout = () => {
		const { fetchBooking, setBookingError, params } = this.props;
		this.setState({ isModalOpen: false });
		const bookingId = params.bookingId || sessionStorage.getItem('exploreshare:bookingId');
		const token = params.token || sessionStorage.getItem('exploreshare:token');

		if (params.data) {
			window.history.pushState({}, '', `/?bookingId=${bookingId}&token=${token}`);
			window.location.reload();
			return;
		}

		if (window.sessionStorage && params.bookingId && params.token) {
			sessionStorage.setItem('exploreshare:bookingId', params.bookingId);
			sessionStorage.setItem('exploreshare:token', params.token);
		}

		this.setState({ bookingId });

		if (bookingId && token) {
			fetchBooking(bookingId, token);
		} else {
			setBookingError();
		}
	};

	goToStep = n => {
		this.setState({ currentStep: n });
	};

	toggleFaq = () => {
		this.setState(prevProps => ({
			faqView: !prevProps.faqView,
		}));
	};

	getActions = (step = 1, data) => {
		const { isFetching, isSubmitting, lead, travellers, postPayment , params} = this.props;
		
		const stepActions = {
			1: {
				primary: {
					label: 'Continue',
					clickHandler: () => this.goToStep(3),
				},
			},
			2: {
				primary: {
					label: 'Continue to Payment',
					clickHandler: () => {
						const { postTravellers } = this.props;
						postTravellers();
						this.goToStep(3);
					},
					disabled: isFetching || !lead.valid || !selectors.areTravellersValid(travellers) || isSubmitting,
				},
				secondary: {
					label: 'Back',
					clickHandler: () => this.goToStep(1),
				},
			},
			3: {
				primary: {
					label: (() => {
						const { method, distribution, daysCount } = this.props;
						if (!distribution) return 'Choose how would you like to pay';
						if (!method) return 'Select a payment method';
						return 'Finish Booking';
					})(),
					clickHandler: () => {
						const { method, distribution, payment } = this.props;
						if (distribution === '75-later' || method === 'cc') {
							validatePayment(payment);
							if (!payment.valid) {
								this.showErrors(payment);
								return;
							}
						} else if (distribution === 'total-now' && method === 'wire') {
							const { phone, address, addressNumber, zip, city, country } = payment;
							const billingInfo = { address, addressNumber, zip, city, country };
							validatePhone(phone);
							validateBillingInfo(billingInfo);
							if (!phone.valid || !billingInfo.valid) {
								this.showErrors(payment);
								return;
							}
						}

						const {currency, bookingId, finalPriceWithFee, title, trip, ...rest} = data;

						dataLayer.push({ ecommerce: null });  
						dataLayer.push({
							event: 'purchase',
							ecommerce: {
								currencyCode: currency, 
									id: bookingId,
									value: finalPriceWithFee,
									distribution,
									item:[ {
										item_name: title,
										item_id: trip._id,
										price: finalPriceWithFee,
										item_category: trip.main_activity,
										...rest
									}
								]
							}
						});

						postPayment();
					},
					disabled: (() => {
						const { method, distribution } = this.props;
						return isFetching || isSubmitting || !distribution || !method;
					})(),
				},
				secondary: {
					label: 'Back',
					clickHandler: () => {
						const { setPaymentMethod, setPaymentDistribution, method, distribution, lock } = this.props;
						if (method && distribution) return setPaymentMethod(undefined);
						if (lock) return this.goToStep(1);
						if (distribution) return setPaymentDistribution(undefined);
						this.goToStep(1);
					},
				},
			},
		};
		return stepActions[step];
	};

	render() {
		const {
			availability,
			duration,
			daysCount,
			whatsIncluded,
			title,
			coverURL,
			pricePerPerson,
			currency,
			finalPrice,
			finalPriceWithFee,
			peopleCount,
			skillLevel,
			fitnessLevel,
			isFetching,
			lead,
			travellers,
			openGroup,
			// setLead,
			// setTravellers,
			payment,
			setPayment,
			guide,
			params,
			trip
		} = this.props;

		const {
			currentStep,
			labels,
			clientData,
			faqView,
			isModalOpen,
			tripPageUrl,
			contactInfoError,
			isModalSubmitting,
			bookingId,
			contactInfo,
		} = this.state;


		validateTravellerInfo(lead);
		travellers.forEach(traveller => validateTravellerInfo(traveller, travellers));
		validatePayment(payment);

		return (
			<Loading isLoading={isFetching && !isModalOpen}>
				{faqView ? (
					<Faq toggleFaq={this.toggleFaq} bookingId={bookingId} />
				) : (
					<>
						<NavBar>
							<div className={styles.navBarContainer}>
								<Image
									src="https://d3rrfy0o57uet0.cloudfront.net/themes/twentysixteen-child/images/E&S-black.svg"
									alt="logo"
									className={styles.image}
								/>
							</div>
						</NavBar>
						<WidgetModal
							isOpen={isModalOpen}
							contactInfo={contactInfo}
							handleChange={this.changeInputValue}
							tripPageUrl={tripPageUrl}
							onSubmit={this.submitModal}
							error={contactInfoError}
							back={this.backToWidgetModal}
							isSubmiting={isModalSubmitting}
						/>
						<CheckoutComponent
							labels={Object.values(labels)}
							step={currentStep}
							actions={this.getActions(currentStep, 
											{openGroup,currency,
												finalPriceWithFee,
												peopleCount, 
												title,
												duration,
												finalPrice,
												pricePerPerson,
												bookingId,
												trip
											 })}
							data={clientData}
							duration={duration}
							title={title}
							coverURL={coverURL}
							pricePerPerson={pricePerPerson}
							currency={currency}
							finalPrice={finalPrice}
							finalPriceWithFee={finalPriceWithFee}
							peopleCount={peopleCount}
							whatsIncluded={whatsIncluded}
							openGroup={openGroup}
							daysCount={daysCount}
							toggleFaq={this.toggleFaq}
							availability={availability}>
							<BookingDetails
								stepKey={1}
								availability={availability}
								duration={duration}
								finalPrice={finalPrice}
								finalPriceWithFee={finalPriceWithFee}
								currency={currency}
								peopleCount={peopleCount}
								skillLevel={skillLevel}
								fitnessLevel={fitnessLevel}
								whatsIncluded={whatsIncluded}
								daysCount={daysCount}
								guide={guide}
								openGroup={openGroup}
							/>
							{/* <TravellersInfo
                stepKey={2}
                setTravellers={setTravellers}
                lead={lead}
                travellers={travellers}
                setLead={setLead}
              /> */}
							<Payment
								stepKey={3}
								data={payment}
								setData={setPayment}
								availability={availability}
								peopleCount={peopleCount}
								currency={currency}
								finalPrice={finalPrice}
								finalPriceWithFee={finalPriceWithFee}
								travellers={travellers}
							/>
						</CheckoutComponent>
					</>
				)}
			</Loading>
		);
	}
}

const mapStateToProps = ({ bookingReducer, app }) => ({
	availability: selectors.getAvaliability(bookingReducer),
	duration: bookingReducer.booking.duration,
	openGroup: bookingReducer.booking.open_group,
	daysCount: selectors.getDaysCount(bookingReducer),
	title: selectors.getTitle(bookingReducer),
	coverURL: selectors.getCoverURL(bookingReducer),
	pricePerPerson: bookingReducer.booking.price_per_person,
	currency: bookingReducer.booking.currency,
	finalPrice: selectors.getFinalPrice(bookingReducer),
	finalPriceWithFee: selectors.getFinalPriceWithFee(bookingReducer),
	whatsIncluded: selectors.getWhatsIncluded(bookingReducer),
	peopleCount: bookingReducer.booking.people_count,
	skillLevel: selectors.getSkillLevel(bookingReducer),
	fitnessLevel: selectors.getFitnessLevel(bookingReducer),
	isFetching: bookingReducer.isFetching,
	isSubmitting: bookingReducer.isSubmitting,
	params: getQueryParams(),
	lead: bookingReducer.lead,
	travellers: bookingReducer.travellers,
	payment: bookingReducer.payment,
	guide: bookingReducer.booking.guide,
	trip: bookingReducer.booking.trip,
	method: app.method,
	distribution: app.distribution,
	lock: app.lock,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators(
		{
			fetchBooking: actions.fetchBooking,
			setBookingError: actions.setBookingError,
			// setLead: actions.setLead,
			// setTravellers: actions.setTravellers,
			setPayment: actions.setPayment,
			postPayment: actions.postPayment,
			postTravellers: actions.postTravellers,
			getBookingSuccess: actions.getBookingSuccess,
			setPaymentMethod: appActions.setPaymentMethod,
			setPaymentDistribution: appActions.setPaymentDistribution,
		},
		dispatch
	);

Checkout.defaultProps = {
	currency: 'EUR',
	duration: 1,
	peopleCount: 1,
	skillLevel: 'Moderate',
};

Checkout.propTypes = {
	fetchBooking: PropTypes.func.isRequired,
	setBookingError: PropTypes.func.isRequired,
	availability: PropTypes.arrayOf(
		PropTypes.shape({
			_id: PropTypes.string.isRequired,
			endDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
			startDate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		})
	),
	duration: PropTypes.number,
	whatsIncluded: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
	coverURL: PropTypes.string,
	pricePerPerson: PropTypes.number,
	currency: PropTypes.string,
	openGroup: PropTypes.string,
	finalPrice: PropTypes.number.isRequired,
	finalPriceWithFee: PropTypes.number.isRequired,
	peopleCount: PropTypes.number,
	skillLevel: PropTypes.string,
	fitnessLevel: PropTypes.string,
	isSubmitting: PropTypes.bool,
	isFetching: PropTypes.bool,
	daysCount: PropTypes.string,
	params: PropTypes.shape({
		bookingId: PropTypes.string,
		token: PropTypes.string,
		data: PropTypes.string,
		locale: PropTypes.string,
	}),
	lead: PropTypes.object,
	travellers: PropTypes.array,
	// setTravellers: PropTypes.func,
	// setLead: PropTypes.func,
	payment: PropTypes.object,
	setPayment: PropTypes.func,
	postPayment: PropTypes.func,
	postTravellers: PropTypes.func,
	guide: PropTypes.object,
	getBookingSuccess: PropTypes.func,
	setPaymentMethod: PropTypes.func,
	setPaymentDistribution: PropTypes.func,
	method: PropTypes.string,
	distribution: PropTypes.string,
	lock: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
